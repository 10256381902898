import FilterService from '../services/filter';

let timeout: ReturnType<typeof setTimeout> | null;

export const fetchBarcode = (
  value: string,
  callback: Function,
  subcategory?: string,
  local_company?: string,
  brand?: string
) => {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }

  const fetchBarcodes = () => {
    FilterService.getBarcode({
      subcategory: subcategory || undefined,
      local_company: local_company || undefined,
      brand: brand || undefined,
      search: value,
    }).then((res) => {
      const data = res?.data?.results?.map((item: any) => ({
        label: item['product'],
        value: item['barcode'],
      }));
      callback(data);
    });
  };

  if (value) {
    timeout = setTimeout(fetchBarcodes, 500);
  }
};
