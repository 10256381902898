import { Checkbox, Form, Switch, Typography } from 'antd';
import styles from './styles.module.scss';
import { hasPermissions } from '../../../utils/hasPermissions';

const { Text } = Typography;
type Props = {
  isModal: boolean;
};

const defaultStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 12,
};

export const ManageFormItemsNew = ({ isModal }: Props) => {
  return (
    <>
      {/* <div className={styles.manage}>
        <div className={styles.item}>
          <Text type="secondary">Roles</Text>

          <Form.Item style={defaultStyles}>
            <Form.Item valuePropName="checked" name={['roles', 'can_create']} noStyle>
              <Checkbox />
            </Form.Item>
            <label>&nbsp;&nbsp;Create</label>
          </Form.Item>

          <Form.Item style={defaultStyles}>
            <Form.Item valuePropName="checked" name={['roles', 'can_edit']} noStyle>
              <Checkbox />
            </Form.Item>
            <label>&nbsp;&nbsp;Edit</label>
          </Form.Item>

          <Form.Item style={defaultStyles}>
            <Form.Item valuePropName="checked" name={['roles', 'can_read']} noStyle>
              <Checkbox />
            </Form.Item>
            <label>&nbsp;&nbsp;View</label>
          </Form.Item>

          <Form.Item style={defaultStyles}>
            <Form.Item valuePropName="checked" name={['roles', 'can_delete']} noStyle>
              <Checkbox />
            </Form.Item>
            <label>&nbsp;&nbsp;Delete</label>
          </Form.Item>
        </div>

        <div className={styles.item}>
          <Text type="secondary">Users</Text>

          <Form.Item style={defaultStyles}>
            <Form.Item valuePropName="checked" name={['users', 'can_create']} noStyle>
              <Checkbox />
            </Form.Item>
            <label>&nbsp;&nbsp;Create</label>
          </Form.Item>

          <Form.Item style={defaultStyles}>
            <Form.Item valuePropName="checked" name={['users', 'can_edit']} noStyle>
              <Checkbox />
            </Form.Item>
            <label>&nbsp;&nbsp;Edit</label>
          </Form.Item>

          <Form.Item style={defaultStyles}>
            <Form.Item valuePropName="checked" name={['users', 'can_read']} noStyle>
              <Checkbox />
            </Form.Item>
            <label>&nbsp;&nbsp;View</label>
          </Form.Item>

          <Form.Item style={defaultStyles}>
            <Form.Item valuePropName="checked" name={['users', 'can_delete']} noStyle>
              <Checkbox />
            </Form.Item>
            <label>&nbsp;&nbsp;Delete</label>
          </Form.Item>
        </div>
      </div> */}

      <div className={styles.item}>
        {hasPermissions('users') && (
          <Form.Item style={defaultStyles}>
            <Form.Item valuePropName="checked" name={['users']} noStyle>
              <Checkbox />
            </Form.Item>
            <label>&nbsp;&nbsp;Users</label>
          </Form.Item>
        )}
      </div>
      <div className={styles.item}>
        {hasPermissions('roles') && (
          <Form.Item style={defaultStyles}>
            <Form.Item valuePropName="checked" name={['roles']} noStyle>
              <Checkbox />
            </Form.Item>
            <label>&nbsp;&nbsp;Roles</label>
          </Form.Item>
        )}
      </div>
      <div className={styles.item}>
        {hasPermissions('journal') && (
          <Form.Item style={defaultStyles}>
            <Form.Item valuePropName="checked" name={['journal']} noStyle>
              <Checkbox />
            </Form.Item>
            <label>&nbsp;&nbsp;User logs</label>
          </Form.Item>
        )}
      </div>
    </>
  );
};
