import { AxiosRequestConfig, AxiosResponse } from 'axios';
import fetchClient from './axiosInstance';

interface PriceElasticityQueryParams {
  period_1_after: string;
  period_1_before: string;
  period_2_after: string;
  period_2_before: string;
  period_3_after?: string;
  period_3_before?: string;
  period_4_after?: string;
  period_4_before?: string;
  period_5_after?: string;
  period_5_before?: string;
  city?: string;
  brand?: string;
  barcode?: string;
  local_company?: string;
  size_of_package?: string;
  subcategory?: string;
  type_of_package?: string;
  active_stores?: boolean;
  validated_stores?: boolean;
}
export default class PriceElasticityService {
  static async getPriceElasticityGraph(
    params: PriceElasticityQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/price_elasticity/graph/`, { params, ...customConfig });
  }

  static async getPriceElasticityTable(
    params: PriceElasticityQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/price_elasticity/table/`, { params, ...customConfig });
  }

  static async exportByType(
    params: PriceElasticityQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/price_elasticity/table/export/`, {
      params,
      ...customConfig,
      // responseType: 'arraybuffer',
      // headers: { 'Content-Type': 'blob' },
    });
  }
}
