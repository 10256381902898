export const downloadFile = (content: string, filename?: string) => {
  const pom = document.createElement('a');
  const bb = new Blob([content], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  pom.setAttribute('href', window.URL.createObjectURL(bb));
  pom.setAttribute('download', filename || 'test');
  pom.dataset['downloadurl'] = [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    pom.download,
    pom.href,
  ].join(':');
  pom.draggable = true;
  pom.classList.add('dragout');
  pom.click();
};
