import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { PageQueryParams } from '../models/pageQueryParams';
import fetchClient from './axiosInstance';

interface PermissionsQueryParams extends PageQueryParams {
  is_active?: boolean;
  role?: string;
}

interface UserLogQueryParams extends PageQueryParams {
  user?: number | string;
  timestamp_after?: string;
  timestamp_before?: string;
  ip?: string;
}

interface Roles extends PageQueryParams {
  search?: string;
  local_company?: string;
  all?: boolean;
}

export default class PermissionsService {
  static async getUsers(params?: PermissionsQueryParams, customConfig?: AxiosRequestConfig): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/users/`, { params, ...customConfig });
  }

  static async getUserById(id: string, customConfig?: AxiosRequestConfig): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/users/${id}`, { ...customConfig });
  }

  static async createUser(body: any): Promise<AxiosResponse> {
    return fetchClient.post(`/api/v1/users/`, body);
  }

  static async editUserById(id: string, body: any): Promise<AxiosResponse> {
    return fetchClient.put(`/api/v1/users/${id}/`, body);
  }

  static async deleteUserById(id: string): Promise<AxiosResponse> {
    return fetchClient.delete(`/api/v1/users/${id}/`);
  }

  static async activateOrDeactivateUser(id: string, val: boolean): Promise<AxiosResponse> {
    return fetchClient.patch(`/api/v1/users/${id}/`, { is_active: val });
  }

  static async getRoles(params?: Roles, customConfig?: AxiosRequestConfig): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/roles/`, { params, ...customConfig });
  }

  static async getRoleById(id: string, customConfig?: AxiosRequestConfig): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/roles/${id}/`, { ...customConfig });
  }

  static async createRole(body: any): Promise<AxiosResponse> {
    return fetchClient.post(`/api/v1/roles/`, body);
  }

  static async editRoleById(id: string, body: any): Promise<AxiosResponse> {
    return fetchClient.put(`/api/v1/roles/${id}/`, body);
  }

  static async deleteRoleById(id: string): Promise<AxiosResponse> {
    return fetchClient.delete(`/api/v1/roles/${id}/`);
  }

  static async activateOrDeactivateRole(id: string, val: boolean): Promise<AxiosResponse> {
    return fetchClient.patch(`/api/v1/roles/${id}/`, { is_active: val });
  }

  static async getUsersLogs(params?: UserLogQueryParams, customConfig?: AxiosRequestConfig): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/journal/`, { params, ...customConfig });
  }

  static async getLastLogs(params?: UserLogQueryParams, customConfig?: AxiosRequestConfig): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/users/last_action/`, { params, ...customConfig });
  }
}
