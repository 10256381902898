import { Modal } from 'antd';
import { LoginForm } from './LoginForm';

export const AuthExpiredSessionModal = ({ isOpen, handleExit }) => {
  return (
    <Modal centered open={isOpen} footer={null} closeIcon={<></>} width={300} onCancel={handleExit}>
      <LoginForm onExit={handleExit} />
    </Modal>
  );
};
