import axios from 'axios';
import { useState } from 'react';
import { Button, Form, Input, Space } from 'antd';
import { WhatsAppOutlined } from '@ant-design/icons';

import desktop from '../../../../assets/landing/desktop.png';
import phone from '../../../../assets/landing/phone.png';
import styles from './styles.module.scss';

const { TextArea } = Input;

const initialValues = {
  first_name: null,
  email: null,
  phone_number: '',
  message: '',
};
export const ContactUs = ({ contactUsRef }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);

  const onRedirectWhatApp = () => {
    window.open('https://wa.me/77016850065?text=Здравствуйте!');
  };
  const onFinish = async (values: any) => {
    setLoading(true);
    const { message, phone_number, email, first_name } = values;
    const apiUrl = 'https://api.datum.kz/api/v1/contact_us/';
    const csrfToken = 'dMKIkoGOzAidOOD4hyfzDXYkWWBEs4fRTIeFhyeleYFKOncWW3j6MCniI5ualMjF';
    try {
      const response = await axios.post(
        apiUrl,
        {
          message,
          phone_number,
          email,
          first_name,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,
          },
        }
      );
      if (response.status === 204) {
        form.setFieldsValue({ ...initialValues });
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className={styles.root} ref={contactUsRef}>
      <h2>Связаться</h2>
      <div className={styles.formLayout}>
        <Form name="send_forn" form={form} onFinish={onFinish} initialValues={initialValues} className={styles.form}>
          <Form.Item name="first_name" rules={[{ required: true, message: 'Введите Имя' }]}>
            <Input placeholder="Имя" />
          </Form.Item>
          <Form.Item name="email" rules={[{ required: true, message: 'Введите почту' }]}>
            <Input placeholder="Почта" />
          </Form.Item>
          <Form.Item name="phone_number" rules={[{ required: true, message: 'Введите номер' }]}>
            <Input placeholder="+7 (___)___-__-__" />
          </Form.Item>
          <Form.Item name="message" rules={[{ required: true, message: 'Введите сообщение' }]}>
            <TextArea placeholder="Сообщение" />
          </Form.Item>
          <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button type="default" onClick={onRedirectWhatApp} icon={<WhatsAppOutlined style={{ color: '#25D366' }} />}>
              Написать на WhatsApp
            </Button>
            <Button type="primary" htmlType="submit" loading={loading} disabled={loading}>
              Отправить
            </Button>
          </Space>
        </Form>
        <div className={styles.platforms}>
          <img src={desktop} alt="" className={styles.desktop} />
          <img src={phone} alt="" className={styles.phone} />
        </div>
      </div>
    </section>
  );
};
