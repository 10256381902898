import { useQuery } from 'react-query';
import FilterService from '../../../services/filter';
import StoresService from '../../../services/stores';

export const useSubcategories = () => {
  return useQuery('subcategories', async () => {
    const { data } = await FilterService.getNewOptionsDev('subcategory');
    return data?.map((item) => ({ label: item.name_val, value: item.id }));
  });
};

export const useCities = (isExtrapolated) => {
  return useQuery(['cities', isExtrapolated], async () => {
    const service = isExtrapolated ? FilterService.getExtrapotableCities : FilterService.getCities;
    const { data } = await service();
    return data?.results;
  });
};

export const useAddress = (search) => {
  return useQuery(['address', search], async () => {
    const { data } = await StoresService.getHashedStores(search);
    return data?.results?.map((item) => ({
      label: `${item['address']} (${item['hashed_store_id']})`,
      value: item['hashed_store_id'],
    }));
  });
};

export const useLocalCompanies = (subcategorysString, companyString) => {
  return useQuery(['localCompanies', subcategorysString, companyString], async () => {
    const { data } = await FilterService.getLocalCompany({
      ...(subcategorysString && { subcategory: subcategorysString }),
    });
    return data?.results?.map((item) => ({ label: item.name_val, value: item.id })) || [];
  });
};

export const useBrands = (type, companyString, subcategorysString, brandsString, valuesFromUrl) => {
  return useQuery(['brands', type, companyString, subcategorysString, brandsString, valuesFromUrl], async () => {
    let service = null;
    if (type === 'brand' || type === 'barcode') {
      service = FilterService.getBrand;
    } else if (type === 'barcode') {
      service = FilterService.getBarcode;
    }

    if (service) {
      const { data } = await service({
        ...(companyString && { local_company: companyString || valuesFromUrl?.local_company }),
        ...(subcategorysString && { subcategory: subcategorysString || valuesFromUrl?.subcategory }),
        ...(brandsString && { brand: brandsString || valuesFromUrl?.brand }),
      });
      return data?.results?.map((item) => ({ label: item.name_val, value: item.id })) || [];
    }

    return [];
  });
};

export const useBarcodes = (companyString, subcategorysString, brandsString, barcodeString, valuesFromUrl) => {
  return useQuery(
    ['barcodes', companyString, subcategorysString, brandsString, barcodeString, valuesFromUrl],
    async () => {
      const { data } = await FilterService.getBarcode({
        ...(companyString && { local_company: companyString || valuesFromUrl?.local_company }),
        ...(subcategorysString && { subcategory: subcategorysString || valuesFromUrl?.subcategory }),
        ...(brandsString && { brand: brandsString || valuesFromUrl?.brand }),
      });
      return data?.results?.map((item) => ({ label: item.product, value: item.barcode })) || [];
    }
  );
};
