import { useEffect, useMemo, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Button, DatePicker, Form, notification, PaginationProps, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';

import styles from './styles.module.scss';
import { useQuery } from 'react-query';
import PermissionsService from '../../services/permissions';
import { AxiosError } from 'axios';
import qs from 'qs';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { removeEmptyObject } from '../../utils/removeEmptyObject';
import { format } from 'date-fns';

const { RangePicker } = DatePicker;

interface DataType {
  key: React.Key;
  name: string;
}

export const DetailedLogsPage = () => {
  const history = useHistory();
  const location = useLocation();
  const [filtersForm] = Form.useForm();
  const { userId } = useParams<{ userId: string }>();
  const [tableData, setTableData] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [userName, setUserName] = useState<string>('');
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;

  const page = +queryParams!.get('page') || 1;
  const limit = +queryParams!.get('limit') || 10;

  const onPageChange: PaginationProps['onChange'] = (page, pageSize) => {
    queryParams.set('page', `${page}`);
    queryParams.set('limit', `${pageSize}`);
    history.replace({ pathname: history.location.pathname, search: queryParams.toString() });
  };

  const { data, isFetching, error } = useQuery(['user-logs', params, userId], ({ signal }) => {
    return PermissionsService.getUsersLogs({ user: userId, limit: +params.limit || 10, ...params }, { signal });
  });

  const openNotification = (msgTitle: string, msgDescription?: string) => {
    notification.info({
      message: msgTitle,
      description: msgDescription,
      placement: 'bottomLeft',
    });
  };

  const columns = useMemo<ColumnsType<DataType>>(
    () => [
      {
        title: 'Recent action time',
        dataIndex: 'timestamp',
        key: 'timestamp',
        width: 90,
        render: (value) => format(new Date(value), 'HH:mm-dd.MM.yyyy'),
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        width: 155,
      },
      {
        title: 'IP address',
        dataIndex: 'ip',
        key: 'ip',
        width: 90,
      },
      {
        title: 'Device',
        dataIndex: 'device',
        key: 'device',
        width: 90,
      },
    ],
    []
  );

  const onNavigateTable = () => {
    history.push('/users-logs');
  };

  const onFinish = (values: any) => {
    let normalized = {} as any;
    if (values.period) {
      const period = {
        timestamp_after: values?.period[0]?.format('YYYY-MM-DD'),
        timestamp_before: values?.period[1]?.format('YYYY-MM-DD'),
      };
      delete values.period;
      values = {
        ...values,
        ...period,
      };
    }
    Object.keys(values).forEach((key) => {
      if (values[key] != null) {
        if (Array.isArray(values[key])) {
          normalized[key] = values[key].join(',');
        } else {
          normalized[key] = values[key];
        }
      }
    });
    history.replace({
      pathname: history.location.pathname,
      search: `page=1&limit=10&${qs.stringify(removeEmptyObject(normalized))}`,
    });
  };

  useEffect(() => {
    if (data) {
      setTableData(data?.data?.results);
      setTotal(data?.data?.count);
      setUserName(data?.data?.results[0]?.user?.username);
    }
    if (error instanceof AxiosError) {
      if (error?.response?.status === 403) {
        openNotification('You do not have permission to perform this action.');
        setTableData([]);
      } else {
        openNotification('Data with your request not found');
        setTableData([]);
        setTotal(0);
        setUserName('');
      }
    }
  }, [data, error]);

  return (
    <div className={styles.company_layout_content}>
      <div className={styles.top_bar}>
        <Button type="default" htmlType="button" onClick={onNavigateTable} icon={<ArrowLeftOutlined />} />
        <div className={styles.title} style={{ marginLeft: '24px' }}>
          {userName}
        </div>
      </div>
      <Form form={filtersForm} layout="inline" onFinish={onFinish}>
        <Form.Item name="period" label="Select period">
          <RangePicker format="YYYY-MM-DD" allowClear={true} />
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Apply
        </Button>
      </Form>

      <div style={{ marginTop: '24px' }}>
        <Table
          columns={columns}
          dataSource={tableData}
          loading={isFetching}
          className={styles.ant_table}
          sticky
          pagination={{
            pageSize: limit,
            defaultPageSize: 10,
            total: total,
            onChange: onPageChange,
            current: page,
            defaultCurrent: 1,
          }}
          bordered
          size="middle"
        />
      </div>
    </div>
  );
};
