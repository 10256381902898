export const helpText = [
  { title: 'Absolute', info: 'сравнение с предыдущим периодом в процентном соотношений.' },
  { title: 'Avg. price', info: 'cредняя цена, Value / Items.' },
  { title: 'Avg. price per litre', info: 'cредняя цена за литр, Value / Volume.' },
  {
    title: 'CDE',
    info: 'коэффициент Эффективности Дистрибуции. Если значение меньше 1 – значит, товар присутствует на полках многих магазинов, но продается не очень хорошо. Если больше 1, то товар востребован, но продается в относительно небольшом количестве магазинов.',
  },
  {
    title: 'Common stores',
    info: 'означает, то что за указанный период в отчете, каждый месяц участвуют одни и те же магазины.',
  },
  { title: 'Comparison', info: 'сравнительный отчет двух или нескольких указанных периодов.' },
  { title: 'Items', info: 'количество проданных единиц.' },
  { title: 'Items %', info: 'доля от общего количества единиц.' },
  { title: 'Linear', info: 'Линейный график.' },
  {
    title: 'Numeric dist.',
    info: 'значение, описывающее во скольких  магазинах доступен продукт, от общего количества магазинов, в процентном соотношений.',
  },
  { title: 'Sales Statistics', info: 'отчет продаж за указанный период.' },
  { title: 'Share', info: 'доля в процентах.' },
  { title: 'Stores', info: 'количество магазинов в которых присутствует продукт.' },
  { title: 'Trends', info: 'сравнительный отчет по месяцам за указанный период.' },
  {
    title: 'Validated stores',
    info: 'валидированные магазины, критерии валидаций(это магазины у которых были транзакций как минимум 25 календарных дней в месяц).',
  },
  { title: 'Value', info: 'сумма денежного оборота в тенге.' },
  { title: 'Value %', info: 'доля денежного оборота от общей суммы.' },
  { title: 'Volume', info: 'количество продажи в литрах.' },
  { title: 'Volume %', info: 'доля от общего количества в литрах.' },
  {
    title: 'Weighted dist.',
    info: 'показывает долю продаж магазинов в категорий, продающих продукт от общих продаж категории во всех магазинах.',
  },
];

export const PriceElasticityInfo = [
  { title: '>1', info: 'Elastic - changes in price yeild a significant change in demand' },
  { title: '1', info: 'Unitary - changes in price yeild a equivalent (percentage) changes in demand' },
  { title: '<1', info: 'Inelastic - changes in price yeild a insignificant change in demand' },
  { title: '0', info: 'Perfectly Inelastic - changes in price yeild no change in demand' },
];
