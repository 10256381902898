import { Modal } from 'antd';
import changeInfoImg from '../../../assets/changeInfo.jpg';

type Props = {
  open: boolean;
  close: () => void;
};

export const ChangeInfoModal = ({ open, close }: Props) => {
  return (
    <Modal title={''} open={open} onCancel={close} width={600} style={{ top: 100 }} footer={null}>
      <img src={changeInfoImg} alt="" width={523} height={153} style={{ margin: 0, padding: 0 }} />
    </Modal>
  );
};
