import styles from './styles.module.scss';
import equalizer from '../../../../assets/landing/analysisIcons/equalizer.svg';
import donut_large from '../../../../assets/landing/analysisIcons/donut_large.svg';
import attach_money from '../../../../assets/landing/analysisIcons/attach_money.svg';
import category from '../../../../assets/landing/analysisIcons/category.svg';
import add_business from '../../../../assets/landing/analysisIcons/add_business.svg';
import compare_arrows from '../../../../assets/landing/analysisIcons/compare_arrows.svg';

const fixtures = [
  { title: 'Объем продаж', icon: equalizer, isActive: true },
  { title: 'Доля в рынке', icon: donut_large, isActive: false },
  { title: 'Средняя цена', icon: attach_money, isActive: false },
  { title: 'Продажи по категориям', icon: category, isActive: false },
  { title: 'Тенденции рынка', icon: add_business, isActive: false },
  { title: 'MTD и YTD сравнение', icon: compare_arrows, isActive: false },
];

export const Analysis = () => {
  return (
    <section className={styles.root}>
      <h2>Анализ</h2>
      <p className={styles.info}>Какую информацию предоставляем?</p>
      <div className={styles.cardList}>
        {fixtures.map((item, index) => (
          <div className={item.isActive ? styles.cardActive : styles.card} key={index}>
            <img src={item.icon} alt="" />
            <p className={item.isActive ? styles.cardActiveTitle : styles.cardTitle}>{item.title}</p>
          </div>
        ))}
      </div>
    </section>
  );
};
