import { Checkbox, Form, Typography } from 'antd';
import styles from './styles.module.scss';
import { hasPermissions } from '../../../utils/hasPermissions';

const { Text } = Typography;
type Props = {
  isModal: boolean;
  permission?: any;
};

const defaultStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 12,
};

export const AnalysisFormItemsNew = ({ isModal, permission }: Props) => {
  return (
    <>
      {/* <div className={isModal ? styles.manageColumn : styles.manage}> */}
      <div className={styles.item}>
        <Text type="secondary">View: </Text>

        {hasPermissions('abc_analysis') && (
          <Form.Item style={defaultStyles}>
            <Form.Item valuePropName="checked" name={['abc_analysis']} noStyle>
              <Checkbox />
            </Form.Item>
            <label>&nbsp;&nbsp;Barcode ABC analysis</label>
          </Form.Item>
        )}

        {hasPermissions('out_of_stock') && (
          <Form.Item style={defaultStyles}>
            <Form.Item valuePropName="checked" name={['out_of_stock']} noStyle>
              <Checkbox />
            </Form.Item>
            <label>&nbsp;&nbsp;Barcode Out of stock</label>
          </Form.Item>
        )}
        {hasPermissions('price_elasticity') && (
          <Form.Item style={defaultStyles}>
            <Form.Item valuePropName="checked" name={['price_elasticity']} noStyle>
              <Checkbox />
            </Form.Item>
            <label>&nbsp;&nbsp;Price elasticity</label>
          </Form.Item>
        )}
        {hasPermissions('product_comparison') && (
          <Form.Item style={defaultStyles}>
            <Form.Item valuePropName="checked" name={['product_comparison']} noStyle>
              <Checkbox />
            </Form.Item>
            <label>&nbsp;&nbsp;Product comparison</label>
          </Form.Item>
        )}

        {hasPermissions('coexistence') && (
          <Form.Item style={defaultStyles}>
            <Form.Item valuePropName="checked" name={['coexistence']} noStyle>
              <Checkbox />
            </Form.Item>
            <label>&nbsp;&nbsp;Co-Existence</label>
          </Form.Item>
        )}

        {hasPermissions('coexistence_trends') && (
          <Form.Item style={defaultStyles}>
            <Form.Item valuePropName="checked" name={['coexistence_trends']} noStyle>
              <Checkbox />
            </Form.Item>
            <label>&nbsp;&nbsp;Co-Existence Trends</label>
          </Form.Item>
        )}

        {hasPermissions('vpo_segmentation') && (
          <Form.Item style={defaultStyles}>
            <Form.Item valuePropName="checked" name={['vpo_segmentation']} noStyle>
              <Checkbox />
            </Form.Item>
            <label>&nbsp;&nbsp;VPO-Segmentation</label>
          </Form.Item>
        )}

        {hasPermissions('vpo_trend') && (
          <Form.Item style={defaultStyles}>
            <Form.Item valuePropName="checked" name={['vpo_trend']} noStyle>
              <Checkbox />
            </Form.Item>
            <label>&nbsp;&nbsp;VPO-Segmentation Trends</label>
          </Form.Item>
        )}

        {permission === 601 && (
          <Form.Item style={defaultStyles}>
            <Form.Item valuePropName="checked" name={['basket_analysis']} noStyle>
              <Checkbox />
            </Form.Item>
            <label>&nbsp;&nbsp;Basket Analysis</label>
          </Form.Item>
        )}
        {hasPermissions('buying_behavior') && (
          <Form.Item style={defaultStyles}>
            <Form.Item valuePropName="checked" name={['buying_behavior']} noStyle>
              <Checkbox />
            </Form.Item>
            <label>&nbsp;&nbsp;Buying behavior</label>
          </Form.Item>
        )}
      </div>
    </>
  );
};
