import { isTobacco } from '../../../utils/isTobacco';

export const useColumns = ({ isTabletOrMobile, pageName }): any => {
  const defaultStyles: React.CSSProperties = {
    margin: 0,
    padding: 0,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: isTabletOrMobile ? '11px' : '14px',
  };
  return [
    {
      title: 'Product name',
      width: 296,
      key: 'barcode',
      hidden: pageName === 'company' || pageName === 'brand',
      render: (value: any) => {
        return (
          <>
            <p style={defaultStyles}>{value.product_name}</p>
            <p
              style={{
                color: '#A9A9A9',
                fontWeight: 400,
                ...defaultStyles,
              }}
            >
              {value.brand} | {value.local_company}
            </p>
          </>
        );
      },
      fixed: !isTabletOrMobile ? 'left' : undefined,
    },
    {
      title: 'Barcode',
      key: 'barcode',
      width: 124,
      hidden: pageName === 'company' || pageName === 'brand',
      render: (value: any) => {
        return <p style={defaultStyles}>{value?.barcode}</p>;
      },
    },
    {
      title: 'Brand',
      width: isTabletOrMobile ? 176 : 192,
      key: 'brand',
      hidden: pageName !== 'brand',
      render: (value: any) => {
        return (
          <>
            <p style={defaultStyles}>{value.brand}</p>
            <p style={{ color: '#A9A9A9', ...defaultStyles }}>{value.local_company}</p>
          </>
        );
      },
      fixed: isTabletOrMobile ? undefined : 'left',
    },
    {
      title: 'Local company',
      width: isTabletOrMobile ? 170 : 188,
      key: 'local_company',
      hidden: pageName === 'brand' || pageName === 'barcode',
      render: (value: any) => {
        return <p style={defaultStyles}>{value.local_company}</p>;
      },
      fixed: isTabletOrMobile ? undefined : 'left',
    },
    {
      title: 'Value kzt',
      width: isTabletOrMobile ? 100 : 140,
      align: 'right',
      render: (value: any) => {
        return (
          <>
            <p style={defaultStyles}>{`${value?.value}`?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ')}</p>
            {value?.value_share && (
              <div style={{ color: '#A9A9A9', fontWeight: 400, ...defaultStyles }}>
                <p>Share: {value?.value_share} %</p>
              </div>
            )}
          </>
        );
      },
    },
    {
      title: 'Items',
      width: isTabletOrMobile ? 100 : 125,
      align: 'right',
      render: (value: any) => {
        return (
          <>
            <p style={{ textAlign: 'right', ...defaultStyles }}>
              {`${value?.items}`?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ')}
            </p>
            {value?.item_share && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  color: '#A9A9A9',
                  fontWeight: 400,
                  ...defaultStyles,
                }}
              >
                <p>Share: {value?.item_share}%</p>
              </div>
            )}
          </>
        );
      },
    },
    {
      title: 'Volume',
      width: isTabletOrMobile ? 100 : 125,
      align: 'right',
      hidden: isTobacco(),
      render: (value: any) => {
        return (
          <>
            {value?.volume ? (
              <>
                <p style={defaultStyles}>{`${value?.volume}`?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ')}</p>
                {value?.volume_share && (
                  <div style={{ color: '#A9A9A9', fontWeight: 400, ...defaultStyles }}>
                    <p>Share: {value?.volume_share}%</p>
                  </div>
                )}
              </>
            ) : (
              '--'
            )}
          </>
        );
      },
    },
    {
      title: 'Numeric Distr.',
      width: isTabletOrMobile ? 80 : 100,
      align: 'right',
      render: (value: any) => {
        return (
          <p style={defaultStyles}>{value?.numeric_distribution === undefined ? '--' : value?.numeric_distribution}</p>
        );
      },
    },
    {
      title: 'Weighted Distr.',
      width: isTabletOrMobile ? 80 : 100,
      align: 'right',
      render: (value: any) => {
        return (
          <p style={defaultStyles}>
            {value?.weighted_distribution === undefined ? '--' : value?.weighted_distribution}
          </p>
        );
      },
    },
  ];
};
