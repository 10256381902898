import { useState } from 'react';
import { Col, Radio, RadioChangeEvent, Row } from 'antd';

import { MarketShareTable } from './components/MarketShareTable';
import { MarketShareChart } from './components/MarketShareChart';
import { LineChartOutlined, TableOutlined } from '@ant-design/icons';

type Props = {
  pageName: string;
  hiddenColumns?: any;
  isMobile?: boolean;
};

export const MarketShareCategory = ({ pageName, hiddenColumns, isMobile }: Props) => {
  const [mode, setMode] = useState<string>('table');
  const rules = JSON.parse(localStorage.getItem('user'))?.role;

  const onChange = (e: RadioChangeEvent) => {
    setMode(e.target?.value || 'table');
  };

  return (
    <>
      <Row gutter={3}>
        <Col className="gutter-row" span={4}>
          <Radio.Group defaultValue="table" buttonStyle="solid" onChange={onChange} style={{ display: 'flex' }}>
            <Radio.Button value="table"> {isMobile ? <TableOutlined /> : 'Table'}</Radio.Button>
            <Radio.Button value="graphic">{isMobile ? <LineChartOutlined /> : 'Graphic'}</Radio.Button>
          </Radio.Group>
        </Col>
      </Row>
      {mode === 'table' ? (
        <MarketShareTable pageName={pageName} rule={rules} hiddenColumns={hiddenColumns} />
      ) : (
        <MarketShareChart pageName={pageName} />
      )}
    </>
  );
};
