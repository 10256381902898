import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import qs from 'qs';
import { AxiosError } from 'axios';
import { Empty, notification, Select } from 'antd';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  ChartDataset,
  BarElement,
} from 'chart.js';
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';

import { backgroundColors } from '../../../utils/randomColor';
import { Loader } from '../../../components/loader/Loader';
import ComparisonService from '../../../services/comparison';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const options = {
  responsive: true,
  interaction: {
    mode: 'index' as const,
    intersect: false,
  },
  stacked: false,
  plugins: {
    legend: {
      position: 'bottom' as const,
    },
    datalabels: {
      display: false,
    },
    title: {
      display: true,
      text: '',
    },
  },
};

type Props = {
  tab: string;
  pageName: string;
};

export const ComparisonChart = ({ tab, pageName }: Props) => {
  const location = useLocation();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 872px)' });
  const [comparisonData, setComparisonData] = useState<ChartDataset[]>();
  const chartMode = [
    { label: 'absolute', value: 'absolute' },
    { label: 'relative', value: 'relative' },
    { label: 'linear', value: 'linear' },
    { label: 'share', value: 'share' },
  ];
  const [currentChartMode, setCurrentChartMode] = useState<string>('absolute');
  const [labels, setLabels] = useState<string[]>(['1', '2', '3', '4', '5']);

  const { data, error, isFetching } = useQuery(['trends', tab, pageName, location.search], ({ signal }) => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
    switch (pageName) {
      case 'company':
        return ComparisonService.getComparisonGraphByCompany(
          {
            measure: tab,
            period_1_after: params?.period_1_after,
            period_1_before: params?.period_1_before,
            period_2_after: params?.period_2_after,
            period_2_before: params?.period_2_before,
            local_company: params?.local_company,
            ...params,
          },
          { signal }
        );

      case 'brand':
        return ComparisonService.getComparisonGraphByBrand(
          {
            measure: tab,
            period_1_after: params?.period_1_after,
            period_1_before: params?.period_1_before,
            period_2_after: params?.period_2_after,
            period_2_before: params?.period_2_before,
            brand: params?.brand,
            ...params,
          },
          { signal }
        );

      case 'barcode':
        return ComparisonService.getComparisonGraphByBarcode(
          {
            measure: tab,
            period_1_after: params?.period_1_after,
            period_1_before: params?.period_1_before,
            period_2_after: params?.period_2_after,
            period_2_before: params?.period_2_before,
            barcode: params?.barcode_product_name,
            ...params,
          },
          { signal }
        );
      default:
        return;
    }
  });

  // const getData = useCallback(() => {
  //   queryClient.refetchQueries('trends', { active: true });
  // }, [queryClient]);

  const openNotification = (msgTitle: string, msgDescription?: string) => {
    notification.info({
      message: msgTitle,
      description: msgDescription,
      placement: 'bottomLeft',
    });
  };

  useEffect(() => {
    if (data) {
      setLabels(data?.data?.additional);
      const values =
        Object.keys(data?.data?.results)?.map((item, index) => {
          return {
            label: item,
            data: data.data?.results[item][currentChartMode] as number[],
            borderColor: backgroundColors[index],
            backgroundColor: backgroundColors[index],
          };
        }) ?? [];
      setComparisonData(values);
    }
    if (error instanceof AxiosError) {
      if (error?.response?.status === 400) {
        openNotification('Fill required fields');
        setComparisonData([]);
      } else if (error?.response?.status === 403) {
        openNotification('You do not have permission to perform this action.');
        setComparisonData([]);
      } else {
        openNotification('Data with your request not found');
        setComparisonData([]);
      }
    }

    return () => {
      setComparisonData([]);
    };
  }, [data, currentChartMode, error]);

  const chartData = {
    labels,
    datasets: comparisonData as ChartDataset[],
  };

  return (
    <>
      {isFetching ? (
        <Loader />
      ) : comparisonData?.length ? (
        <>
          <div style={{ marginTop: '24px' }}>
            <label>Chart type:</label>
            <Select options={chartMode} defaultValue={currentChartMode} onChange={(e) => setCurrentChartMode(e)} />
          </div>
          <Chart
            type="bar"
            data={chartData}
            options={{ ...options, aspectRatio: isTabletOrMobile ? 1 : undefined }}
            height={100}
          />
        </>
      ) : (
        <Empty />
      )}
    </>
  );
};
