import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Input, notification, PaginationProps, Table, Typography } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { AxiosError } from 'axios';
import type { ColumnsType } from 'antd/es/table';
import { useQuery } from 'react-query';
import qs from 'qs';

import VpoSegmentationService from '../../../services/vpoSegmentation';
import styles from './styles.module.scss';

const { Text } = Typography;
interface DataType {
  key: React.Key;
  name: string;
}

type Props = {
  salesStep?: number;
  debounceChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const SalesTable = ({ salesStep, debounceChange }: Props) => {
  const location = useLocation();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 872px)' });
  const [tableData, setTableData] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [totalInfo, setTotalInfo] = useState<Record<string, string | number>>({});

  const { data, isFetching, error } = useQuery(['salesTable', location.search, page, salesStep], ({ signal }) => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
    delete params?.page;
    return VpoSegmentationService.getSale(
      { brand: params?.brand, months: params?.months, step: salesStep, limit: 10, page: page, ...params },
      { signal }
    );
  });

  const onPageChange: PaginationProps['onChange'] = (page) => {
    setPage(page);
  };

  const openNotification = (msgTitle: string, msgDescription?: string) => {
    notification.info({
      message: msgTitle,
      description: msgDescription,
      placement: 'bottomLeft',
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    debounceChange(event);
  };

  useEffect(() => {
    if (data) {
      setTableData(data?.data?.results);
      setTotal(data?.data?.count);
      setTotalInfo(data?.data?.additional[0]);
    }
    if (error instanceof AxiosError) {
      if (error?.response?.status === 400) {
        openNotification('Fill required fields');
        setTableData([]);
      } else if (error?.response?.status === 403) {
        openNotification('You do not have permission to perform this action.');
        setTableData([]);
      } else {
        openNotification('Data with your request not found');
        setTableData([]);
      }
    }

    return () => {
      setTableData([]);
    };
  }, [data, error, location.search]);

  const columns = useMemo<ColumnsType<DataType>>(
    () => [
      {
        title: 'Items',
        dataIndex: 'items',
        width: 1,
        key: 'items',
      },
      {
        title: 'Stores',
        dataIndex: 'stores',
        width: 1,
        key: 'stores',
      },
      {
        title: 'share',
        dataIndex: 'share',
        width: 1,
        key: 'share',
        render: (value: any) => {
          return `${value} %`;
        },
      },
    ],
    []
  );

  return (
    <div style={{ width: isTabletOrMobile ? '100%' : '48%' }}>
      Sales
      <span style={{ marginTop: '24px', marginLeft: '24px' }}>
        <label>Item step: </label>
        <Input defaultValue="6" onChange={handleChange} type="number" style={{ width: '100px' }} />
      </span>
      <Table
        columns={columns}
        dataSource={tableData}
        loading={isFetching}
        bordered
        size="small"
        scroll={{ x: 200 }}
        pagination={{
          pageSize: 10,
          defaultPageSize: 10,
          total: total,
          onChange: onPageChange,
          current: page,
          defaultCurrent: 1,
        }}
        summary={() => {
          return (
            <>
              <Table.Summary.Row>
                {totalInfo && (
                  <>
                    <Table.Summary.Cell index={0}>
                      <Text className={styles.total_bold}>{totalInfo?.items}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1}>
                      <Text className={styles.total_bold}>{totalInfo?.stores}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2}>
                      <Text className={styles.total_bold}>{totalInfo?.share ? `${totalInfo?.share} %` : '--'}</Text>
                    </Table.Summary.Cell>
                  </>
                )}
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </div>
  );
};
