import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { PageQueryParams } from '../models/pageQueryParams';
import fetchClient from './axiosInstance';

interface VpoSegmentationQueryParams extends PageQueryParams {
  months: string;
  brand: string;
  step?: string | number;
  city?: string;
  barcode?: string;
  local_company?: string;
  size_of_package?: string;
  subcategory?: string;
  type_of_package?: string;
  active_stores?: boolean;
  validated_stores?: boolean;
}
export default class VpoSegmentationService {
  static async getSale(params: VpoSegmentationQueryParams, customConfig?: AxiosRequestConfig): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/vpo_segmentation/sale/`, { params, ...customConfig });
  }

  static async getVpoTrendSale(
    params: VpoSegmentationQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/vpo_trend/sale/`, { params, ...customConfig });
  }

  static async getSupply(
    params: VpoSegmentationQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/vpo_segmentation/supply/`, { params, ...customConfig });
  }

  static async getVpoTrendSupply(
    params: VpoSegmentationQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/vpo_trend/supply/`, { params, ...customConfig });
  }

  static async exportByType(
    type: 'supply' | 'sale',
    params: VpoSegmentationQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/vpo_segmentation/${type}_export/`, {
      params,
      ...customConfig,
      // responseType: 'arraybuffer',
      // headers: { 'Content-Type': 'blob' },
    });
  }

  static async exportVpoTrendByType(
    type: 'supply' | 'sale',
    params: VpoSegmentationQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/vpo_trend/${type}_export/`, {
      params,
      ...customConfig,
      // responseType: 'arraybuffer',
      // headers: { 'Content-Type': 'blob' },
    });
  }
}
