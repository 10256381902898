import { Modal, Radio, RadioChangeEvent, Space } from 'antd';
import { useState } from 'react';

type Props = {
  open: boolean;
  close: () => void;
};

export const InformationModal = ({ open, close }: Props) => {
  const [tabValue, setTabValue] = useState<'en' | 'ru'>('ru');

  const changeTabPosition = (e: RadioChangeEvent) => {
    setTabValue(e.target.value);
  };

  return (
    <Modal title={''} open={open} onCancel={close} width={600} style={{ top: 20 }} footer={null}>
      <Space style={{ marginTop: 16, marginBottom: 16 }}>
        <Radio.Group value={tabValue} onChange={changeTabPosition}>
          <Radio.Button value="ru">RU</Radio.Button>
          <Radio.Button value="en">EN</Radio.Button>
        </Radio.Group>
      </Space>
      {tabValue === 'ru' ? (
        <div>
          <b>Понимание отчета VPO Segmentation (Объем на торговую точку)</b>
          <p>
            Отчет VPO Segmentation (Объем на торговую точку) предоставляет информацию о распределении продаж продукта по
            разным магазинам. Он показывает, сколько магазинов продали различное количество определенного продукта, что
            помогает понять объем продаж по торговым точкам.
          </p>
          <b>1. Структура отчета</b>
          <p>Отчет разделен на две основные части:</p>
          <ul>
            <li>
              <p>
                <strong>Продажи</strong> (слева)
              </p>
            </li>
            <li>
              <p>
                <strong>Поставка</strong> (справа)
              </p>
            </li>
          </ul>
          <p>Каждая часть включает следующие столбцы:</p>
          <ul>
            <li>
              <p>
                <strong>Товары</strong> Диапазон количества проданных или поставленных товаров (например, 1- 6, 7-12 и
                т.д.).
              </p>
            </li>
            <li>
              <p>
                <strong>Магазины</strong> Количество магазинов, которые попадают в каждый диапазон количества.
              </p>
            </li>
            <li>
              <p>
                <strong>Доля</strong> Процентная доля каждого диапазона относительно общего числа магазинов.
              </p>
            </li>
          </ul>
          <b>2. Интерпретация раздела "Продажи"</b>
          <p>
            Раздел <strong>Продажи</strong> показывает, сколько магазинов продали определенное количество бутылок.
          </p>
          <ul>
            <li>
              <p>
                <strong>Пример:</strong>Диапазон "1-6" указывает, что 437 магазинов продали от 1 до 6 бутылок, что
                составляет 10,21% от всех магазинов.
              </p>
            </li>
          </ul>
          <p>Этот разбор помогает быстро выявить распределение объемов продаж по всем магазинам.</p>
          <b>3. Интерпретация раздела "Поставка"</b>
          <p>
            Раздел <strong>Поставка</strong>имеет ту же структуру, что и раздел Продаж, но фокусируется на распределении
            поставок по магазинам, а не на фактических продажах.
          </p>
          <ul>
            <li>
              <p>
                <strong>Пример:</strong>Для диапазона "1-6" 53 магазина получили от 1 до 6 бутылок, что составляет 2,13%
                от всех магазинов.
              </p>
            </li>
          </ul>

          <b>4. Использование фильтра и шага товаров</b>
          <ul>
            <li>
              <p>
                <strong>Фильтр:</strong> Позволяет применить фильтры для уточнения данных.
              </p>
            </li>
            <li>
              <p>
                <strong>Шаг товаров (Step):</strong> Этот параметр (установлен на 6 по умолчанию) определяет диапазон
                каждой группы товаров. Изменение этого параметра изменит группировку (например, 1-6, 7-12).
              </p>
            </li>
          </ul>

          <b>Пример</b>
          <p>Если мы разберем одну из строк:</p>
          <p>Для сегмента Продажи :</p>
          <ul>
            <li>
              <p>
                <strong>Товарами</strong> "1-6"
              </p>
            </li>
            <li>
              <p>
                <strong>Магазины</strong> 437
              </p>
            </li>
            <li>
              <p>
                <strong>Доля</strong> 10.21%
              </p>
            </li>
            <p>Интерпретация: 437 магазинов (10,21% от всех магазинов) продали от 1 до 6 бутылок продукта.</p>
          </ul>

          <b>Заключение</b>
          <p>Отчет VPO Segmentation полезен для понимания:</p>
          <ul>
            <li>
              <p>Как объемы продаж и поставок распределяются по разным магазинам.</p>
            </li>
            <li>
              <p>Какие диапазоны количества имеют наибольшее и наименьшее распределение.</p>
            </li>
            <p>
              Эта информация может помочь определить потенциальные области для роста, оптимизировать цепочки поставок и
              корректировать маркетинговые стратегии в зависимости от эффективности магазинов.
            </p>
          </ul>
        </div>
      ) : (
        <div>
          <b>Understanding the VPO Segmentation Report</b>
          <p>
            The VPO (Volume per Outlet) Segmentation report provides insights into the distribution of product sales
            across different stores. It essentially shows how many stores sold varying quantities of a specific product,
            which helps in understanding the sales volume across outlets.
          </p>
          <b>1. Layout of the Report</b>
          <p>The report is divided into two main sections:</p>
          <ul>
            <li>
              <p>
                <strong>Sales</strong> (on the left)
              </p>
            </li>
            <li>
              <p>
                <strong>Supply</strong> (on the right)
              </p>
            </li>
          </ul>
          <p>The report is divided into two main sections:</p>
          <ul>
            <li>
              <p>
                <strong>Items</strong> The range of product quantities sold or supplied (e.g., 1-6, 7-12, etc.).
              </p>
            </li>
            <li>
              <p>
                <strong>Stores</strong> The number of stores that fall within each quantity range.
              </p>
            </li>
            <li>
              <p>
                <strong>Share</strong> The percentage share of each range relative to the total stores.
              </p>
            </li>
          </ul>
          <b>2. Interpreting the Sales Section</b>
          <p>
            The <strong>Sales</strong> section shows how many stores sold a specified number of bottles.
          </p>
          <ul>
            <li>
              <p>
                <strong>Example:</strong> The range "1-6" indicates that 437 stores sold between 1 and 6 bottles,
                representing 10.21% of all stores.
              </p>
            </li>
          </ul>
          <p>This breakdown helps to quickly identify the distribution of sales volumes across all stores.</p>
          <b>3. Interpreting the Supply Section</b>
          <p>
            The <strong>Supply</strong>section reflects the same structure as Sales, but it focuses on the supply
            distribution across stores rather than actual sales.
          </p>
          <ul>
            <li>
              <p>
                <strong>Example:</strong>For the range "1-6," 53 stores received between 1 and 6 bottles, representing
                2.13% of all stores..
              </p>
            </li>
          </ul>

          <b>4. Using the Filter and Item Step Options</b>
          <ul>
            <li>
              <p>
                <strong>Filter:</strong> alows you to apply filters to refine the data.
              </p>
            </li>
            <li>
              <p>
                <strong>Item Step:</strong> This option (set to 6 by default) determines the range of each item group.
                Adjusting this will change the groupings (e.g., 1-6, 7-12).
              </p>
            </li>
          </ul>

          <b>Example</b>
          <p>If we break down one of the rows:</p>
          <p>For the Sales segment:</p>
          <ul>
            <li>
              <p>
                <strong>Items</strong> "1-6"
              </p>
            </li>
            <li>
              <p>
                <strong>Stores</strong> 437
              </p>
            </li>
            <li>
              <p>
                <strong>Share</strong> 10.21%
              </p>
            </li>
            <p>Interpretation: 437 stores (10.21% of all stores) sold between 1 and 6 bottles of the product.</p>
          </ul>

          <b>Conclusion</b>
          <p>The VPO Segmentation report is valuable for understanding:</p>
          <ul>
            <li>
              <p>How product sales and supply volumes are distributed across different stores.</p>
            </li>
            <li>
              <p>Which quantity ranges have the highest and lowest distribution.</p>
            </li>
            <p>
              This information can help identify potential areas for growth, optimize supply chains, and adjust
              marketing strategies based on store performance.
            </p>
          </ul>
        </div>
      )}
    </Modal>
  );
};
