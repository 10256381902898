import axios from 'axios';
import AuthService, { AuthStatus } from './auth';

const reportsApiClient = () => {
  const defaultOptions = {
    baseURL: 'https://dev.api.reports.datum.kz',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // Create instance
  let instance = axios.create(defaultOptions);

  // Set the AUTH token for any request
  instance.interceptors.request.use(function (config) {
    if (config.headers === undefined) {
      config.headers = {};
    }
    const token = localStorage.getItem('token');
    const category = localStorage.getItem('category');
    const user = JSON.parse(localStorage.getItem('user'));
    const userFirstCategory = user?.company?.categories[0]?.id || 1;
    config.headers!.Authorization = token ? `Bearer ${token}` : '';

    config.params = {
      ...config.params,
      // category: category !== null ? category : userFirstCategory,
      category_id: category !== null ? category : userFirstCategory,
    };
    return config;
  });

  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response?.status === 401) {
        AuthService.getInstance.loginStatus.emit(AuthStatus.UNAUTHORIZED, true);
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

export default reportsApiClient();
