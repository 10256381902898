import { Modal, Radio, RadioChangeEvent, Space } from 'antd';
import { useState } from 'react';
import outOfStock1 from '../../../assets/outOfStock1.png';
import outOfStock2 from '../../../assets/outOfStock2.png';

type Props = {
  open: boolean;
  close: () => void;
};

export const InformationModal = ({ open, close }: Props) => {
  const [tabValue, setTabValue] = useState<'en' | 'ru'>('ru');

  const changeTabPosition = (e: RadioChangeEvent) => {
    setTabValue(e.target.value);
  };

  return (
    <Modal title={''} open={open} onCancel={close} width={600} style={{ top: 20 }} footer={null}>
      <Space style={{ marginTop: 16, marginBottom: 16 }}>
        <Radio.Group value={tabValue} onChange={changeTabPosition}>
          <Radio.Button value="ru">RU</Radio.Button>
          <Radio.Button value="en">EN</Radio.Button>
        </Radio.Group>
      </Space>
      {tabValue === 'ru' ? (
        <div>
          <b>Что такое out of stock</b>
          <p>Отсутствие товара на прилавке, для поставщика это означает недополученный доход.</p>
          <img
            src={outOfStock1}
            alt=""
            width={577}
            height={216}
            style={{ marginLeft: '-20px', margin: 0, padding: 0 }}
          />
          <p>На примере можем увидить то что по Продукту 1 </p>
          <p>&nbsp;&nbsp;&nbsp;в г.Алматы out of stock был на 3,5%</p>
          <p>&nbsp;&nbsp;&nbsp;в г.Астана out of stock был на 1%</p>
          <p>Или можно было продать на 3,5% и 1% больше в Алмате и Астане.</p>
          <b>Как вычисляется OOS%</b>
          <img src={outOfStock2} alt="" width={477} height={316} style={{ margin: 0, padding: 0 }} />
          <p>1. Смотрим продажи продукта за месяц по дням.</p>
          <p>2. Вычисляем среднюю продажу по Продукту за месяц (желтая линия на рис.)</p>
          <p>
            3. Высчитываем среднее расстояние между средней и каждой продажей, потом отнимаем найденное среднее
            расстояние от средней продажи, так ставим порог (красная линия) ниже которой предполагаем что был out of
            stock.{' '}
          </p>
          <p>
            4. Tеперь смотрим на дни, где продажи были ниже красной линии и считаем расстояние до красной линии. Так
            получаем объём, который не допродали из-за out of stock.
          </p>
          <p>5. Считаем долю этого объёма от продаж в этот месяц, в итоге получаем OOS%.</p>
        </div>
      ) : (
        <div>
          <b>What is out of stock</b>
          <p>
            Condition, stating that a product is not currently available for sale, which means lost income for the
            supplier.
          </p>
          <img
            src={outOfStock1}
            alt=""
            width={577}
            height={216}
            style={{ marginLeft: '-20px', margin: 0, padding: 0 }}
          />
          <p>Using an example, we can see what is happening for Product 1</p>
          <p>&nbsp;&nbsp;&nbsp; in Almaty out of stock was 3.5%</p>
          <p>&nbsp;&nbsp;&nbsp;in Astana out of stock was by 1% </p>
          <p>Or it was possible to sell 3.5% and 1% more in Almaty and Astana.</p>
          <b>How is OOS% calculated?</b>
          <img src={outOfStock2} alt="" width={477} height={316} style={{ margin: 0, padding: 0 }} />
          <p>1. We look at product sales for the month by day.</p>
          <p>2. We calculate the average sales per Product per month (yellow line in the figure)</p>
          <p>
            3. We calculate the average distance between the average and each sale, then subtract the found average
            distance from the average sale, so we set a threshold (red line) below which we assume that it was out of
            stock.
          </p>
          <p>
            4. Now we look at the days where sales were below the red line and calculate the distance to the red line.
            This is how we get the volume that was not sold due to out of stock.
          </p>
          <p>5. We calculate the share of this volume from sales for this month, and as a result we get OOS%.</p>
        </div>
      )}
    </Modal>
  );
};
