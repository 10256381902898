export const useColumns = ({ isTabletOrMobile }): any => {
  return [
    {
      title: 'Product name',
      width: 155,
      key: 'barcode',
      render: (value: any) => {
        return value ? `${value.product_name} (${value.barcode})` : '-';
      },
    },
    {
      title: 'Brand',
      dataIndex: 'brand',
      key: 'brand',
      width: 90,
    },
    {
      title: 'OOS %',
      dataIndex: 'oos',
      key: 'oos',
      width: isTabletOrMobile ? 70 : 40,
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      width: 90,
    },
  ];
};
