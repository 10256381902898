import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Button, Layout } from 'antd';

import { Logo } from '../../Logo';
import styles from './styles.module.scss';

const { Header } = Layout;

const fixtures = [
  { label: 'Как это работает', key: 'hiw', ref: 'topBlockRef' },
  { label: 'Преимущества', key: 'advantages', ref: 'advantagesRef' },
  { label: 'География', key: 'map', ref: 'mapRef' },
  { label: 'Контакты', key: 'contacts', ref: 'contactUsRef' },
];

export const NavHomeHeader = ({ onItemClick, refs }) => {
  const history = useHistory();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' });

  return (
    <Header className={styles.headerHome}>
      <>
        <div className={styles.contentHome}>
          <div className={styles.logo}>
            <div className="logo-svg">
              <Logo />
            </div>
          </div>
          {!isTabletOrMobile && (
            <div className={styles.navList}>
              {fixtures.map((item) => (
                <p
                  key={item.key}
                  className={styles.cardTitle}
                  onClick={() => {
                    onItemClick(refs[item.ref]);
                  }}
                >
                  {item.label}
                </p>
              ))}
            </div>
          )}
        </div>
        <div className="info">
          <div style={{ cursor: 'pointer' }}>
            <Button
              type="text"
              onClick={() => history.push('/login')}
              style={{
                marginRight: !isTabletOrMobile ? '135px' : 0,
                color: 'white',
                fontSize: !isTabletOrMobile ? 16 : 14,
                width: !isTabletOrMobile ? '170px' : '130px',
                borderRadius: '10px',
                backgroundColor: '#1890FF',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              Войти
            </Button>
          </div>
        </div>
      </>
    </Header>
  );
};
