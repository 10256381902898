import { notification } from 'antd';

const monthsOptions = [
  { label: 'January', value: '01' },
  { label: 'February', value: '02' },
  { label: 'March', value: '03' },
  { label: 'April', value: '04' },
  { label: 'May', value: '05' },
  { label: 'June', value: '06' },
  { label: 'July', value: '07' },
  { label: 'August', value: '08' },
  { label: 'September', value: '09' },
  { label: 'October', value: '10' },
  { label: 'November', value: '11' },
  { label: 'December', value: '12' },
];

export const getLastThreeMonths = () => {
  const today = new Date();
  const lastThreeMonths = [];
  for (let i = 1; i < 4; i++) {
    lastThreeMonths.unshift(monthsOptions[today.getMonth() - i]);
  }
  return lastThreeMonths;
};

export const dateRange = (startDate: string, endDate: string) => {
  const start = startDate?.split('-') || [null];
  const end = endDate?.split('-') || [null];
  const startYear = parseInt(start[0]);
  const endYear = parseInt(end[0]);
  const dates = [];

  for (let i = startYear; i <= endYear; i++) {
    const endMonth = i !== endYear ? 11 : parseInt(end[1]) - 1;
    const startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
    for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
      const month = j + 1;
      const displayMonth = month < 10 ? '0' + month : month;
      dates.push([i, displayMonth].join(''));
    }
  }
  return dates;
};

export const capitalize = (s: string) => s?.charAt(0)?.toUpperCase() + s?.slice(1)?.toLowerCase();

export const openNotification = (msgTitle: string, type: 'success' | 'error', msgDescription?: string) => {
  notification[type]({
    message: msgTitle,
    description: msgDescription,
    placement: 'bottomLeft',
  });
};
