import { useCallback, useEffect, useMemo, useState } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation, withRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import qs from 'qs';

import { AuthStatus } from './services/auth';
import { useAuthService } from './context/authContext';
import { Profile } from './models/authModel';
import { Loader } from './components/loader/Loader';
import { Login } from './pages/login/login';
import { ForgetPassword } from './pages/login/ForgetPassword';
import { RestorePassword } from './pages/login/RestorePassword';
import { PublicRoute } from './router/PublicRoute';
import { PrivateRoute } from './router/PrivateRoute';
import { AppRouter } from './router/AppRouter';
import { HomePage } from './components/home/HomePage';
import 'antd/dist/reset.css';
import { AuthExpiredSessionModal } from './pages/login/components/AuthExpiredSessionModal';

export default function App() {
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [profile, setProfile] = useState<Profile>();
  const authService = useAuthService();
  const routeProps = useMemo(() => ({ profile, loading }), [profile, loading]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const valuesFromUrl = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
  const tokenFromUrl = valuesFromUrl?.token;

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 0,
        refetchOnWindowFocus: false,
      },
    },
  });

  const logout = useCallback(() => {
    if (history.location.pathname !== '/restore') {
      localStorage.removeItem('token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('user');
      localStorage.removeItem('category');
      setProfile(null);
      if (location.pathname !== '/home' && location.pathname !== '/login') {
        setIsModalOpen(true);
      }
    }
  }, [history, location.pathname]);

  useEffect(() => {
    if (tokenFromUrl) {
      localStorage.setItem('token', tokenFromUrl);
    } else if (!localStorage.getItem('token')) {
      logout();
    }
  }, [tokenFromUrl, logout]);

  useEffect(() => {
    authService.loginStatus.on(AuthStatus.UNAUTHORIZED, logout);

    return () => {
      authService.loginStatus.off(AuthStatus.UNAUTHORIZED, logout);
    };
  }, [authService, logout]);

  console.log(process.env);

  useEffect(() => {
    setLoading(true);
    authService
      .getProfile()
      .then((profile) => {
        if (typeof profile === 'string') {
          throw Error('profile must be a object');
        } else {
          setProfile(profile);
          localStorage.setItem('user', JSON.stringify(profile));
        }
      })
      .catch((err) => {
        localStorage.removeItem('user');
        console.error(err);
        handleExit();
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {
      setProfile(null);
    };
  }, [authService]);

  const handleExit = () => {
    history.push('/login');
    setIsModalOpen(false);
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      <Switch>
        <Route exact path="/" render={() => (profile ? <Redirect to="/sales/company" /> : <Redirect to="/home" />)} />
        <Route path="/forget" component={withRouter(ForgetPassword)} />
        <Route path="/restore" component={withRouter(RestorePassword)} />
        <Route path="/home" component={withRouter(HomePage)} />
        <QueryClientProvider client={queryClient}>
          <PrivateRoute path="/" component={AppRouter} logout={logout} {...routeProps} />
          <PublicRoute path="/login" component={Login} {...routeProps} />
        </QueryClientProvider>
        <Route exact path="*" render={() => <Redirect to="/sales/company" />} />
      </Switch>
      <AuthExpiredSessionModal isOpen={isModalOpen} handleExit={handleExit} />
    </>
  );
}
