import { Layout } from 'antd';
import { ReactElement } from 'react';
import { NavHomeHeader as Header } from '../header/HomeHeader/HomeHeader';
import './publicLayout.css';

const { Content } = Layout;

type Props = {
  children: ReactElement[] | ReactElement;
  onItemClick: (ref) => void;
  refs: any;
};

export const PublicLayout = ({ children, onItemClick, refs }: Props) => {
  return (
    <Layout className="layout">
      <Header onItemClick={onItemClick} refs={{ ...refs }} />
      <Content>{children}</Content>
    </Layout>
  );
};
