import { DownloadsDrawer } from '../../components/DownloadsDrawer/DownloadsDrawer';
import styles from './styles.module.scss';

export const DownloadsPage = () => {
  return (
    <div className={styles.company_layout_content}>
      <DownloadsDrawer open />
    </div>
  );
};
