import { InfoCircleOutlined } from '@ant-design/icons';
import DownRedIcon from '../../../assets/down-red.svg';
import UpGreenIcon from '../../../assets/up-green.svg';
import { Switch } from 'antd';

export const useColumns = ({
  pageName,
  isTabletOrMobile,
  tab,
  tableData,
  setOpenModal,
  isShareActive,
  setIsShareActive,
  periodLabels,
}): any => {
  const defaultStyles: React.CSSProperties = {
    margin: 0,
    padding: 0,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontSize: isTabletOrMobile ? '11px' : '14px',
  };
  return [
    {
      title: 'Product name',
      width: !isTabletOrMobile ? 262 : 144,
      key: 'barcode',
      hidden: pageName === 'company' || pageName === 'brand',
      render: (value: any) => {
        return (
          <>
            <p style={defaultStyles}>{value.product_name}</p>
            <p
              style={{
                color: '#A9A9A9',
                fontWeight: 400,
                ...defaultStyles,
              }}
            >
              {value.brand} | {value.local_company}
            </p>
          </>
        );
      },
      fixed: !isTabletOrMobile ? 'left' : undefined,
    },
    {
      title: 'Barcode',
      key: 'barcode',
      width: !isTabletOrMobile ? 120 : 70,
      hidden: pageName === 'company' || pageName === 'brand',
      render: (value: any) => {
        return <p style={defaultStyles}>{value?.barcode}</p>;
      },
    },
    {
      title: 'Brand',
      width: !isTabletOrMobile ? 194 : 144,
      key: 'brand',
      hidden: pageName !== 'brand',
      render: (value: any) => {
        return (
          <>
            <p style={defaultStyles}>{value.brand}</p>
            <p
              style={{
                color: '#A9A9A9',
                fontWeight: 400,
                ...defaultStyles,
              }}
            >
              {value.local_company}
            </p>
          </>
        );
      },
      fixed: !isTabletOrMobile ? 'left' : undefined,
    },
    {
      title: 'Local company',
      width: 190,
      key: 'local_company',
      hidden: pageName === 'brand' || pageName === 'barcode',
      render: (value: any) => {
        return <p style={defaultStyles}>{value.local_company}</p>;
      },
      fixed: !isTabletOrMobile ? 'left' : undefined,
    },
    ...(tableData[0]?.data?.map((item: any, index: number) => {
      const children = [
        {
          title: tab,
          width: !isTabletOrMobile ? 100 : 45,
          align: 'right',
          render: (value: any) => {
            return (
              <>
                <p style={defaultStyles}>
                  {`${value?.data[index]?.[tab]}`?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ')}
                </p>
                <p
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    color: '#A9A9A9',
                    fontWeight: 400,
                    ...defaultStyles,
                  }}
                >
                  Share: {value?.data[index]?.[`share_relative_${tab}`]} %
                </p>
              </>
            );
          },
        },
        {
          title: 'Stores',
          dataIndex: 'data',
          width: !isTabletOrMobile ? 100 : 45,
          align: 'right',
          render: (value: any) => {
            return `${value[index]?.stores === undefined ? '--' : value[index]?.stores}`;
          },
        },
        {
          title: (
            <span style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p style={{ padding: 0, margin: 0, cursor: 'pointer' }} onClick={() => setOpenModal(true)}>
                <InfoCircleOutlined />
              </p>
              <p style={{ padding: 0, margin: 0 }}>
                Change &nbsp;&nbsp;
                <Switch
                  size="small"
                  checked={isShareActive}
                  onChange={(checked) => setIsShareActive(checked)}
                  checkedChildren="%"
                  unCheckedChildren="₸"
                />
              </p>
            </span>
          ),
          width: !isTabletOrMobile ? 100 : 45,
          align: 'right',
          render: (value: any) => {
            return isShareActive ? (
              <>
                <span
                  style={{
                    display: 'flex',
                    justifyContent:
                      value?.data[index]?.[`share_absolute_${tab}`] === '--' ? 'flex-end' : 'space-between',
                  }}
                >
                  {value?.data[index]?.[`share_absolute_${tab}`] === '--' ? (
                    ''
                  ) : (
                    <img
                      src={value?.data[index]?.[`share_absolute_${tab}`] > 0 ? UpGreenIcon : DownRedIcon}
                      alt="red"
                    />
                  )}
                  <p style={defaultStyles}>Absolute: {value?.data[index]?.[`share_absolute_${tab}`]} %</p>
                </span>

                <span
                  style={{
                    display: 'flex',
                    justifyContent:
                      value?.data[index]?.[`share_relative_${tab}`] === '--' ? 'flex-end' : 'space-between',
                  }}
                >
                  {value?.data[index]?.[`share_relative_${tab}`] === '--' ? (
                    ''
                  ) : (
                    <img
                      src={value?.data[index]?.[`share_relative_${tab}`] > 0 ? UpGreenIcon : DownRedIcon}
                      alt="green"
                    />
                  )}
                  <p
                    style={{
                      textAlign: 'right',
                      ...defaultStyles,
                    }}
                  >
                    Relative: {value?.data[index]?.[`share_relative_${tab}`]} %
                  </p>
                </span>
              </>
            ) : (
              <>
                <span
                  style={{
                    display: 'flex',
                    justifyContent: value?.data[index]?.[`absolute_${tab}`] === '--' ? 'flex-end' : 'space-between',
                  }}
                >
                  {value?.data[index]?.[`absolute_${tab}`] === '--' ? (
                    ''
                  ) : (
                    <img src={value?.data[index]?.[`absolute_${tab}`] > 0 ? UpGreenIcon : DownRedIcon} alt="red" />
                  )}
                  <p style={defaultStyles}>Absolute: {value?.data[index]?.[`absolute_${tab}`]} %</p>
                </span>

                <span
                  style={{
                    display: 'flex',
                    justifyContent: value?.data[index]?.[`relative_${tab}`] === '--' ? 'flex-end' : 'space-between',
                  }}
                >
                  {value?.data[index]?.[`relative_${tab}`] === '--' ? (
                    ''
                  ) : (
                    <img src={value?.data[index]?.[`relative_${tab}`] > 0 ? UpGreenIcon : DownRedIcon} alt="green" />
                  )}
                  <p
                    style={{
                      textAlign: 'right',
                      ...defaultStyles,
                    }}
                  >
                    Relative: {value?.data[index]?.[`relative_${tab}`]} %
                  </p>
                </span>
              </>
            );
          },
        },
      ];
      return {
        title: periodLabels[0][item.periods] || item?.periods,
        children,
        align: 'center',
      };
    }) || []),
  ];
};
