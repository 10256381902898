import { Radio } from 'antd';

const options = [
  { label: 'By company', value: 'company' },
  { label: 'By brand', value: 'brand' },
  { label: 'By barcode', value: 'barcode' },
];
type Props = {
  onChange: (e: any) => void;
  value: any;
};
export const RadioGroupMenu = ({ onChange, value }: Props) => {
  return <Radio.Group options={options} onChange={onChange} value={value} optionType="button" buttonStyle="solid" />;
};
