import styles from './styles.module.scss';
import pace from '../../../../assets/landing/advantagesIcons/pace.svg';
import computer from '../../../../assets/landing/advantagesIcons/computer.svg';
import bar_chart_4_bars from '../../../../assets/landing/advantagesIcons/bar_chart_4_bars.svg';
import auto_fix_high from '../../../../assets/landing/advantagesIcons/auto_fix_high.svg';

const fixtures = [
  { title: 'Данные в реальном времени', icon: pace },
  { title: 'Онлайн платформа', icon: computer },
  { title: 'Интерактивные отчеты', icon: bar_chart_4_bars },
  { title: 'KPI-инструмент для отдела продаж', icon: auto_fix_high },
];

export const Advantages = ({ advantagesRef }) => {
  return (
    <section className={styles.root} ref={advantagesRef}>
      <h2>Преимущества</h2>
      <div className={styles.cardList}>
        {fixtures.map((item, index) => (
          <div className={styles.card} key={index}>
            <img src={item.icon} alt="" />
            <p className={styles.cardTitle}>{item.title}</p>
          </div>
        ))}
      </div>
    </section>
  );
};
