import { useState } from 'react';
import { Col, Radio, RadioChangeEvent, Row } from 'antd';
import { LineChartOutlined, TableOutlined } from '@ant-design/icons';

import { TrendsShareTable } from './components/TrendsShareTable';
import { TrendsShareChart } from './components/TrendsShareChart';

type Props = {
  pageName: string;
  rule?: unknown;
  hiddenColumns?: unknown;
  isMobile?: boolean;
};

export const TrendsShareCategory = ({ pageName, rule, hiddenColumns, isMobile }: Props) => {
  const [mode, setMode] = useState<string>('table');

  const onChange = (e: RadioChangeEvent) => {
    setMode(e.target?.value || 'table');
  };

  return (
    <>
      <Row gutter={3}>
        <Col className="gutter-row" span={4}>
          <Radio.Group defaultValue="table" buttonStyle="solid" onChange={onChange} style={{ display: 'flex' }}>
            <Radio.Button value="table"> {isMobile ? <TableOutlined /> : 'Table'}</Radio.Button>
            <Radio.Button value="graphic">{isMobile ? <LineChartOutlined /> : 'Graphic'}</Radio.Button>
          </Radio.Group>
        </Col>
      </Row>
      {mode === 'table' ? (
        <TrendsShareTable pageName={pageName} rule={rule} hiddenColumns={hiddenColumns} />
      ) : (
        <TrendsShareChart pageName={pageName} rule={rule} />
      )}
    </>
  );
};
