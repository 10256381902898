import { Modal } from 'antd';

type Props = {
  open: boolean;
  close: () => void;
  title?: string;
  description?: string;
  loading?: boolean;
  onSubmit?: () => void;
};

export const ConfirmationModal = ({ open, close, title, description, loading, onSubmit }: Props) => {
  return (
    <Modal title={title} open={open} onOk={onSubmit} confirmLoading={loading} onCancel={close}>
      <p>{description}</p>
    </Modal>
  );
};
