import { useEffect, useState } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { Button, Drawer, Dropdown, Menu, RadioChangeEvent, notification } from 'antd';
import { FilterOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons';
import qs from 'qs';
import { format } from 'date-fns';
import { useMediaQuery } from 'react-responsive';

import { CustomFilter } from '../../components/filter/CustomFilter';
import { SalesStatisticsTable } from './components/SalesStatisticsTable';
import SalesStatisticsService from '../../services/salesStatistics';
import { ToggleMenuItems } from '../../components/toggleMenuItems/ToggleMenuitems';
import { RadioGroupMenu } from '../../components/RadioGroupMenu/RadioGroupMenu';
// import { downloadFile } from '../../utils/downloadFile';
import { openNotification } from '../../utils/lastThreeMonths';
import { isTobacco } from '../../utils/isTobacco';
import { DownloadsDrawer } from '../../components/DownloadsDrawer/DownloadsDrawer';
import styles from './styles.module.scss';

const ExportMenuItems = (onDownload: (type: 'report' | 'detailedReport') => void) => (
  <Menu>
    <Menu.Item key="report">
      <div onClick={() => onDownload('report')}>Report</div>
    </Menu.Item>
    <Menu.Item key="detailedReport">
      <div onClick={() => onDownload('detailedReport')}>Detailed report</div>
    </Menu.Item>
  </Menu>
);

export const SalesStatisticsPage = () => {
  const location = useLocation();
  const history = useHistory();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 872px)' });
  const { type } = useParams<{ type: string; tab: string }>();
  const [openFilter, setOpenFilter] = useState(false);
  const [isExtrapolated, setIsExtrapolated] = useState<boolean>(false);
  const [page, setPage] = useState<string>('company');
  const [isOpenDownloads, setIsOpenDonwloads] = useState<boolean>(false);

  const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
  const rules = JSON.parse(localStorage.getItem('user'))?.role;
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const defaultDate = format(new Date().setMonth(new Date().getMonth() - 1), 'yyyy-MM')
    .split('-')
    .join('');

  const [open, setOpen] = useState(false);
  const [hiddenColumns, setHiddenColumns] = useState({
    value: { title: 'Value kzt', value: false },
    items: { title: 'Items', value: false },
    ...(!isTobacco() && { volume: { title: 'Volume', value: false } }),
    avg_price_per_pcs: { title: 'Avg. price', value: false },
    ...(!isTobacco() && { avg_price_per_litre: { title: 'Avg. price per litre', value: false } }),
    numeric_dist: { title: 'Numeric dist.', value: false },
    weight: { title: 'Weighted dist.', value: false },
    cde: { title: 'CDE', value: false },
    stores: { title: 'Stores', value: false },
  });

  const onChangePage = ({ target: { value } }: RadioChangeEvent) => {
    setPage(value);
    history.push({
      pathname: `/sales/${value}`,
      search: `${history.location.search}`,
    });
  };

  useEffect(() => {
    if (location.pathname.includes('company')) {
      setPage('company');
    } else if (location.pathname.includes('brand')) {
      setPage('brand');
    } else if (location.pathname.includes('barcode')) {
      setPage('barcode');
    }
    return () => {
      setPage('company');
    };
  }, [location.pathname]);

  const onClickExtrapolated = (checked: boolean) => {
    setIsExtrapolated(checked);
  };

  const onOpenFilter = () => {
    setOpenFilter(true);
  };

  const onFilterChange = () => {
    setOpenFilter(false);
  };

  const hide = () => {
    setOpenFilter(false);
  };

  const handleOpenChange = (flag: boolean) => {
    setOpen(flag);
  };

  const handleToggle = (key) => {
    setHiddenColumns((prevColumns) => ({
      ...prevColumns,
      [key]: { ...prevColumns[key], value: !prevColumns[key].value },
    }));
  };

  const closeDownloads = () => {
    setIsOpenDonwloads(false);
  };

  const handleDownloadFile = () => {
    setExportLoading(true);
    isExtrapolated
      ? SalesStatisticsService.exportExtrapolationByType(type === 'company' ? 'local_company' : type, {
          months: params?.months || defaultDate,
          ...params,
        })
          .then((res) => {
            if (res.data) {
              setIsOpenDonwloads(true);
              openNotification('File added to downloads folder', 'success');
            }
            // downloadFile(res.data, `sales_report_${type}_${params?.months || defaultDate}` || 'sales_report');
          })
          .catch((err) => openNotification('Something went wrong', 'error'))
          .finally(() => setExportLoading(false))
      : SalesStatisticsService.exportByType(type === 'company' ? 'local_company' : type, {
          months: params?.months || defaultDate,
          ...params,
        })
          .then((res) => {
            if (res.data) {
              setIsOpenDonwloads(true);
              openNotification('File added to downloads folder', 'success');
            }
            // downloadFile(res.data, `sales_report_${type}_${params?.months || defaultDate}` || 'sales_report');
          })
          .catch((err) => openNotification('Something went wrong', 'error'))
          .finally(() => setExportLoading(false));
  };

  const handleDownloadDetailedReport = (mode: 'report' | 'detailedReport') => {
    if (mode === 'detailedReport') {
      setExportLoading(true);
      SalesStatisticsService.exportScanData({ months: params?.months || defaultDate, ...params })
        .then((res) => {
          if (res.data) {
            setIsOpenDonwloads(true);
            openNotification('File added to downloads folder', 'success');
          }
          // downloadFile(res.data, `detailedReport_${type}_${params?.months || defaultDate}` || 'detailed_report');
        })
        .catch((err) => openNotification('Something went wrong', 'error'))
        .finally(() => setExportLoading(false));
    } else {
      handleDownloadFile();
    }
  };

  return (
    <div className={styles.company_layout_content}>
      <div className={styles.top_bar}>
        <div className={styles.wrapper}>
          {!isTabletOrMobile && (
            <div className={styles.title}>
              <RadioGroupMenu onChange={onChangePage} value={page} />
            </div>
          )}

          {!isTabletOrMobile && (
            <>
              <div style={{ marginLeft: '15px' }}>
                <Drawer title="" placement="left" onClose={hide} open={openFilter}>
                  <CustomFilter
                    type={type}
                    pageName="sales"
                    onFilterChange={onFilterChange}
                    onCancel={hide}
                    isExtrapolated={isExtrapolated}
                    setExtrapolated={onClickExtrapolated}
                  />
                </Drawer>
                <Button
                  type="default"
                  className={styles.company_filter_btn}
                  onClick={onOpenFilter}
                  icon={<FilterOutlined />}
                >
                  &nbsp;&nbsp;Filter
                </Button>
              </div>
            </>
          )}
          {isTabletOrMobile && (
            <div>
              <Drawer
                title=""
                placement="left"
                onClose={hide}
                open={openFilter}
                width={isTabletOrMobile ? '320' : undefined}
              >
                <CustomFilter
                  type={type}
                  pageName="sales"
                  onFilterChange={onFilterChange}
                  onCancel={hide}
                  isExtrapolated={isExtrapolated}
                  setExtrapolated={onClickExtrapolated}
                />
              </Drawer>
              <Button
                type="default"
                className={styles.company_filter_btn}
                onClick={onOpenFilter}
                icon={<FilterOutlined />}
              />
            </div>
          )}
        </div>
        <Dropdown
          menu={ToggleMenuItems(hiddenColumns, handleToggle)}
          placement="bottom"
          onOpenChange={handleOpenChange}
          open={open}
        >
          <Button>Table settings</Button>
        </Dropdown>
        &nbsp;&nbsp;&nbsp;
        {type === 'barcode' ? (
          <Dropdown overlay={ExportMenuItems(handleDownloadDetailedReport)}>
            <Button type="default" className={styles.export_btn} loading={exportLoading}>
              <VerticalAlignBottomOutlined />
              Export
            </Button>
          </Dropdown>
        ) : (
          <Button type="default" className={styles.export_btn} onClick={handleDownloadFile} loading={exportLoading}>
            <VerticalAlignBottomOutlined />
            Export
          </Button>
        )}
      </div>

      <SalesStatisticsTable
        isExtrapolation={isExtrapolated}
        pageName={type}
        rule={rules}
        hiddenColumns={hiddenColumns}
        isMobile={isTabletOrMobile}
      />

      <Drawer title="Downloads" width="auto" placement="right" onClose={closeDownloads} open={isOpenDownloads}>
        <DownloadsDrawer open={isOpenDownloads} />
      </Drawer>
    </div>
  );
};
