import StoresService from '../services/stores';

let timeout: ReturnType<typeof setTimeout> | null;

export const fetchAddress = (value: string, callback: Function) => {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }

  const fetchAddress = () => {
    StoresService.getHashedStores({ search: value, limit: 20 }).then((res) => {
      const data = res.data?.results?.map((item: any) => ({
        label: `${item['address']} (${item['hashed_store_id']})`,
        value: item['hashed_store_id'],
      }));
      callback(data);
    });
  };

  if (value) {
    timeout = setTimeout(fetchAddress, 500);
  }
};
