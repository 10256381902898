import { PriceElasticityTable } from './components/Table';

export const PriceElasticityCategory = () => {
  // const [mode, setMode] = useState<string>('table');

  // const onChange = (e: RadioChangeEvent) => {
  //   setMode(e.target?.value || 'table');
  // };

  return (
    // <>
    //   <Row gutter={3}>
    //     <Col className="gutter-row" span={4}>
    //       <Radio.Group defaultValue="table" buttonStyle="solid" onChange={onChange}>
    //         <Radio.Button value="table">Table</Radio.Button>
    //         {/* <Radio.Button value="graphic">Graphic</Radio.Button> */}
    //       </Radio.Group>
    //     </Col>
    //   </Row>

    // </>
    <PriceElasticityTable />
  );
};
