import { Button, DatePicker, Form, TreeSelect } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';

import { useBarcodes, useBrands, useCities, useLocalCompanies } from './hook/useFilterHook';
import { removeEmptyObject } from '../../../utils/removeEmptyObject';
import { SelectItem } from '../../../components/form';
import FilterService from '../../../services/filter';
import { Barcode } from '../../../models';
import { fetchBarcode } from '../../../utils/fetchBarcode';

const { RangePicker } = DatePicker;

type Props = {
  type?: string;
  onFilterChange?: () => void;
  onCancel?: () => void;
  disabledMode?: boolean;
};

const defaultValues = {
  monthsRange: [] as string[],
  subcategory: [] as string[],
  city: [] as string[],
};

export const ComparisonProductFilter = ({ onFilterChange, onCancel }: Props) => {
  const history = useHistory();
  const location = useLocation();
  const [filtersForm] = Form.useForm();
  const [lastTransaction, setLastTransation] = useState<string>('');

  const [companyString_1, setCompanyString_1] = useState<string>('');
  const [companyString_2, setCompanyString_2] = useState<string>('');

  const [brandsString_1, setBrandsString_1] = useState<string>('');
  const [brandsString_2, setBrandsString_2] = useState<string>('');

  const [brandSearch_1, setBrandSearch_1] = useState<string>('');
  const [brandSearch_2, setBrandSearch_2] = useState<string>('');

  const [barcodeSearch_1, setBarcodeSearch_1] = useState('');
  const [barcodeSearch_2, setBarcodeSearch_2] = useState('');

  const valuesFromUrl = qs.parse(history.location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
  const defaultRegions = JSON.parse(localStorage.getItem('user'))?.role?.regions?.map(String);
  const validateMessages = { required: 'Required field!' };

  const { data: citiesData } = useCities();
  const { data: localCompaniesData, isFetching: localCompaniesIsFetching } = useLocalCompanies();
  const { data: brandsData_1, isFetching: brandsIsFetching_1 } = useBrands(
    companyString_1,
    brandSearch_1,
    valuesFromUrl
  );

  const { data: brandsData_2, isFetching: brandsIsFetching_2 } = useBrands(
    companyString_2,
    brandSearch_2,
    valuesFromUrl
  );

  const { data: barcodesData_1, isFetching: barcodesIsFetching_1 } = useBarcodes(
    companyString_1,
    brandsString_1,
    barcodeSearch_1,
    valuesFromUrl
  );

  const { data: barcodesData_2, isFetching: barcodesIsFetching_2 } = useBarcodes(
    companyString_2,
    brandsString_2,
    barcodeSearch_2,
    valuesFromUrl
  );
  const [barcodes_1, setBarcodes_1] = useState<Barcode[]>([]);
  const [barcodes_2, setBarcodes_2] = useState<Barcode[]>([]);
  const [barcodesArray_1, setBarcodesArray_1] = useState([]);
  const [barcodesArray_2, setBarcodesArray_2] = useState([]);
  const [barcodeString_1, setBarcodeString_1] = useState<string>('');
  const [barcodeString_2, setBarcodeString_2] = useState<string>('');

  const onFinish = (values: any) => {
    if (values.monthsRange) {
      const period_after = values?.monthsRange[0]?.format('YYYY-MM-DD');
      const period_before = values?.monthsRange[1]?.format('YYYY-MM-DD');
      delete values.monthsRange;
      values = {
        ...values,
        period_after,
        period_before,
      };
    }

    let normalized = {} as any;
    Object.keys(values).forEach((key) => {
      if (values[key] != null) {
        if (Array.isArray(values[key])) {
          normalized[key] = values[key].join(',');
        } else {
          normalized[key] = values[key];
        }
      }
    });
    history.replace({
      pathname: history.location.pathname,
      search: `page=1&limit=10&${qs.stringify(removeEmptyObject(normalized))}`,
    });

    onFilterChange();
  };

  useEffect(() => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
    if (Object.keys(params).length === 0) {
      filtersForm.resetFields(null);
    }
  }, [location.search, filtersForm]);

  const onReset = () => {
    history.replace({
      pathname: history.location.pathname,
      search: `page=1&limit=10&`,
    });
    filtersForm.setFieldsValue({
      ...defaultValues,
      ...(valuesFromUrl?.local_company_1 && { local_company_1: [] }),
      ...(valuesFromUrl?.local_company_2 && { local_company_2: [] }),
      ...(valuesFromUrl?.brand_1 && { brand_1: [] as string[] }),
      ...(valuesFromUrl?.brand_2 && { brand_2: [] as string[] }),
      ...(valuesFromUrl?.barcode_1 && { barcode_1: [] as string[] }),
      ...(valuesFromUrl?.barcode_2 && { barcode_2: [] as string[] }),
    });
  };

  const initialValues = {
    period: [{}, {}],
    city: valuesFromUrl?.city?.split(',') || defaultRegions,
    ...(valuesFromUrl?.local_company_1 && {
      local_company_1: valuesFromUrl?.local_company_1.split(',').map(Number) || [],
    }),
    ...(valuesFromUrl?.local_company_2 && {
      local_company_2: valuesFromUrl?.local_company_2.split(',').map(Number) || [],
    }),
    ...(valuesFromUrl?.brand_1 && { brand_1: valuesFromUrl?.brand_1.split(',').map(Number) || [] }),
    ...(valuesFromUrl?.brand_2 && { brand_2: valuesFromUrl?.brand_2.split(',').map(Number) || [] }),
    ...(valuesFromUrl?.barcode_1 && { barcode_1: valuesFromUrl?.barcode_1.split(',').map(Number) || [] }),
    ...(valuesFromUrl?.barcode_2 && { barcode_2: valuesFromUrl?.barcode_2.split(',').map(Number) || [] }),
  };

  const fetchLastTransactions = useCallback(() => {
    FilterService.getLastTransaction()
      .then((res) => setLastTransation(res.data?.last_transaction))
      .catch((err) => setLastTransation(''));
  }, []);

  const handleSearchBrand_1 = (value) => {
    if (value?.length >= 3) {
      setBrandSearch_1(value);
    }
  };

  const handleSearchBrand_2 = (value) => {
    if (value?.length >= 3) {
      setBrandSearch_2(value);
    }
  };

  // const handleSearchBarcode_1 = (value) => {
  //   if (value?.length >= 3) {
  //     setBarcodeSearch_1(value);
  //   }
  // };

  // const handleSearchBarcode_2 = (value) => {
  //   if (value?.length >= 3) {
  //     setBarcodeSearch_2(value);
  //   }
  // };

  // useEffect(() => {
  //   if (brandsString_1) {
  //     setBrandSearch_1('');
  //   }
  //   if (brandsString_2) {
  //     setBrandSearch_2('');
  //   }
  // }, [brandsString_1, brandsString_2]);

  const handleClearBarcode_1 = () => {
    setBarcodeSearch_1('');
  };

  const handleClearBarcode_2 = () => {
    setBarcodeSearch_2('');
  };

  const handleSearchBarcode_1 = (value) => {
    if (value?.length >= 3) {
      fetchBarcode(value, setBarcodes_1, companyString_1, brandsString_1);
    }
  };

  const handleBarcodeChange_1 = useCallback(
    (value) => {
      setBarcodeString_1(typeof value === 'object' ? value?.join(',') : value);
    },
    [setBarcodeString_1]
  );

  const handleDropdownVisibleBarcodeChange_1 = useCallback(
    (open) => {
      if (!open) {
        handleBarcodeChange_1(barcodesArray_1);
      }
    },
    [handleBarcodeChange_1, barcodesArray_1]
  );

  const handleDeselectBarcodeValue_1 = useCallback(
    (val) => {
      const arr = barcodesArray_1?.filter((el) => el !== val);
      handleBarcodeChange_1(arr);
    },
    [handleBarcodeChange_1, barcodesArray_1]
  );

  const handleSearchBarcode_2 = (value) => {
    if (value?.length >= 3) {
      fetchBarcode(value, setBarcodes_2, companyString_2, brandsString_2);
    }
  };

  const handleBarcodeChange_2 = useCallback(
    (value) => {
      setBarcodeString_2(typeof value === 'object' ? value?.join(',') : value);
    },
    [setBarcodeString_2]
  );

  const handleDropdownVisibleBarcodeChange_2 = useCallback(
    (open) => {
      if (!open) {
        handleBarcodeChange_2(barcodesArray_2);
      }
    },
    [handleBarcodeChange_2, barcodesArray_2]
  );

  const handleDeselectBarcodeValue_2 = useCallback(
    (val) => {
      const arr = barcodesArray_2?.filter((el) => el !== val);
      handleBarcodeChange_2(arr);
    },
    [handleBarcodeChange_2, barcodesArray_2]
  );

  useEffect(() => {
    fetchLastTransactions();
  }, [fetchLastTransactions]);

  useEffect(() => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
    if (Object.keys(params).length === 0) {
      filtersForm.resetFields(null);
    }
  }, [location.search, filtersForm]);

  useEffect(() => {
    if (barcodesData_1) {
      setBarcodes_1(barcodesData_1);
    }
  }, [barcodesData_1]);

  useEffect(() => {
    if (barcodesData_2) {
      setBarcodes_2(barcodesData_2);
    }
  }, [barcodesData_2]);

  return (
    <Form
      form={filtersForm}
      layout="vertical"
      onFinish={onFinish}
      validateMessages={validateMessages}
      initialValues={initialValues}
    >
      <div className="filter-container">
        <Form.Item>
          <label className={'required'}>Months {lastTransaction && `(up to date ${lastTransaction})`}</label>
          <Form.Item name="monthsRange" noStyle rules={[{ required: true }]}>
            <RangePicker format="YYYY-MM-DD" picker="date" allowClear={true} style={{ width: '100%' }} />
          </Form.Item>
        </Form.Item>

        <Form.Item>
          <label>Region</label>
          <Form.Item name="city" noStyle>
            <TreeSelect
              treeCheckable={true}
              labelInValue={false}
              placeholder="Please select"
              maxTagCount="responsive"
              allowClear
              treeDefaultExpandedKeys={['all']}
              filterTreeNode
              treeNodeFilterProp="title"
              treeData={[
                {
                  title: <span>Select all</span>,
                  value: 'all',
                  key: 'all',
                  children: citiesData,
                },
              ]}
            />
          </Form.Item>
        </Form.Item>

        <Form.Item>
          <label>Local company 1</label>
          <SelectItem
            name="local_company_1"
            disabledMode={true}
            options={localCompaniesData}
            placeholder="Choose a local company"
            loading={localCompaniesIsFetching}
            maxTagCount="responsive"
            allowClear={true}
            onChange={(value) => {
              setCompanyString_1(value);
            }}
          />
        </Form.Item>

        <Form.Item>
          <label>Brand 1</label>
          <SelectItem
            name="brand_1"
            disabledMode={true}
            options={brandsData_1}
            placeholder="Choose a brand 1"
            loading={brandsIsFetching_1}
            maxTagCount="responsive"
            allowClear={true}
            onSearch={handleSearchBrand_1}
            onChange={(value) => {
              setBrandsString_1(value);
            }}
          />
        </Form.Item>

        <Form.Item>
          <label className="required">Product name 1</label>
          <SelectItem
            disabledMode={true}
            name="barcode_1"
            options={barcodes_1 || []}
            placeholder="Choose a product name"
            loading={barcodesIsFetching_1}
            maxTagCount="responsive"
            allowClear={true}
            filterOption={false}
            onDropdownVisibleChange={handleDropdownVisibleBarcodeChange_1}
            onDeselect={handleDeselectBarcodeValue_1}
            onSearch={handleSearchBarcode_1}
            onChange={(value) => {
              setBarcodesArray_1(value);
            }}
          />
        </Form.Item>

        <Form.Item>
          <label>Local company 2</label>
          <SelectItem
            name="local_company_2"
            disabledMode={true}
            options={localCompaniesData}
            placeholder="Choose a local company"
            loading={localCompaniesIsFetching}
            maxTagCount="responsive"
            allowClear={true}
            onChange={(value) => {
              setCompanyString_2(value);
            }}
          />
        </Form.Item>

        <Form.Item>
          <label>Brand 2</label>
          <SelectItem
            name="brand_2"
            disabledMode={true}
            options={brandsData_2}
            placeholder="Choose a brand 2"
            loading={brandsIsFetching_2}
            maxTagCount="responsive"
            allowClear={true}
            onSearch={handleSearchBrand_2}
            onChange={(value) => {
              setBrandsString_2(value);
            }}
          />
        </Form.Item>

        <Form.Item>
          <label className="required">Product name 2</label>
          <SelectItem
            disabledMode={true}
            name="barcode_2"
            options={barcodes_2 || []}
            placeholder="Choose a product name"
            loading={barcodesIsFetching_2}
            maxTagCount="responsive"
            allowClear={true}
            filterOption={false}
            onDropdownVisibleChange={handleDropdownVisibleBarcodeChange_2}
            onDeselect={handleDeselectBarcodeValue_2}
            onSearch={handleSearchBarcode_2}
            onChange={(value) => {
              setBarcodesArray_2(value);
            }}
          />
        </Form.Item>
      </div>

      <div className="buttons-wrapper">
        <span style={{ color: '#1890FF', fontSize: '14px', cursor: 'pointer' }} onClick={() => onReset()}>
          Reset
        </span>
        <div>
          <Button style={{ marginRight: '10px' }} onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Apply
          </Button>
        </div>
      </div>
    </Form>
  );
};
