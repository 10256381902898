export const useColumns = ({ tableData, totalInfo, isMobile }): any => {
  const defaultStyles: React.CSSProperties = {
    margin: 0,
    padding: 0,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontSize: isMobile ? '11px' : '14px',
  };

  const mobileColumns = [
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      width: 115,
    },
    ...(tableData[0]?.data?.map((item: any, index: number) => {
      const product_1 = [
        {
          title: '',
          width: 45,
          align: 'left',
          key: item?.supply_barcode_1,
          render: (value: any) => {
            return (
              <>
                <p style={defaultStyles}>
                  Supply: &nbsp;
                  {value?.data[index]?.supply_barcode_1}
                </p>
                <p
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    fontWeight: 400,
                    ...defaultStyles,
                  }}
                >
                  Sale: &nbsp;{value?.data[index]?.sale_barcode_1}
                </p>
              </>
            );
          },
        },
      ];
      return {
        title: <p style={{ fontSize: 11 }}>{totalInfo[0]?.product_names?.barcode_1 || 'Product 1'}</p>,
        children: product_1,
      };
    }) || []),
    ...(tableData[0]?.data?.map((item: any, index: number) => {
      const product_2 = [
        {
          title: '',
          width: 45,
          align: 'left',
          render: (value: any) => {
            return (
              <>
                <p style={defaultStyles}>Supply: &nbsp; {value?.data[index]?.supply_barcode_2}</p>
                <p
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    fontWeight: 400,
                    ...defaultStyles,
                  }}
                >
                  Sale: &nbsp; {value?.data[index]?.sale_barcode_2}
                </p>
              </>
            );
          },
        },
      ];
      return {
        title: <p style={{ fontSize: 11 }}>{totalInfo[0]?.product_names?.barcode_2 || 'Product 2'}</p>,
        children: product_2,
      };
    }) || []),
  ];

  return isMobile
    ? mobileColumns
    : [
        {
          title: 'City',
          dataIndex: 'city',
          key: 'city',
          width: 115,
          fixed: isMobile ? undefined : 'left',
        },
        ...(tableData[0]?.data?.map((item: any, index: number) => {
          const supllyChildren = [
            {
              title: totalInfo[0]?.product_names?.barcode_1 || 'Product 1',
              dataIndex: `data`,
              key: item?.supply_barcode_1,
              width: 100,
              render: (value: any) => {
                return `${
                  value[index]?.supply_barcode_1 === undefined
                    ? '--'
                    : `${value[index]?.supply_barcode_1?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ')}`
                }`;
              },
            },
            {
              title: totalInfo[0]?.product_names?.barcode_2 || 'Product 2',
              dataIndex: `data`,
              key: item?.supply_barcode_2,
              width: 100,
              render: (value: any) => {
                return `${
                  value[index]?.supply_barcode_2 === undefined
                    ? '--'
                    : `${value[index]?.supply_barcode_2?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ')}`
                }`;
              },
            },
            {
              title: 'Difference',
              dataIndex: `data`,
              key: item?.barcode,
              width: 100,
              render: (value: any) => {
                return `${
                  value[index]?.supply_difference === undefined
                    ? '--'
                    : `${value[index]?.supply_difference?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ')}`
                }`;
              },
            },
            {
              title: 'Difference in %',
              dataIndex: `data`,
              key: item?.barcode,
              width: 100,
              render: (value: any) => {
                return `${
                  value[index]?.supply_difference_percent === undefined
                    ? '--'
                    : `${value[index]?.supply_difference_percent?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ')}`
                }`;
              },
            },
          ];
          return {
            title: 'Supply average price',
            children: supllyChildren,
          };
        }) || []),
        ...(tableData[0]?.data?.map((item: any, index: number) => {
          const sellChildren = [
            {
              title: totalInfo[0]?.product_names?.barcode_1 || 'Product 1',
              dataIndex: `data`,
              key: item?.sale_barcode_1,
              width: 100,
              render: (value: any) => {
                return `${
                  value[index]?.sale_barcode_1 === undefined
                    ? '--'
                    : `${value[index]?.sale_barcode_1?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ')}`
                }`;
              },
            },
            {
              title: totalInfo[0]?.product_names?.barcode_2 || 'Product 2',
              dataIndex: `data`,
              key: item?.sale_barcode_2,
              width: 100,
              render: (value: any) => {
                return `${
                  value[index]?.sale_barcode_2 === undefined
                    ? '--'
                    : `${value[index]?.sale_barcode_2?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ')}`
                }`;
              },
            },
            {
              title: 'Difference',
              dataIndex: `data`,
              key: item?.sale_difference,
              width: 100,
              render: (value: any) => {
                return `${
                  value[index]?.sale_difference === undefined
                    ? '--'
                    : `${value[index]?.sale_difference?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ')}`
                }`;
              },
            },
            {
              title: 'Difference in %',
              dataIndex: `data`,
              key: item?.sale_difference_percent,
              width: 100,
              render: (value: any) => {
                return `${
                  value[index]?.sale_difference_percent === undefined
                    ? '--'
                    : `${value[index]?.sale_difference_percent?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ')}`
                }`;
              },
            },
          ];
          return {
            title: 'Sell out average price',
            children: sellChildren,
          };
        }) || []),
      ];
};
