import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { notification, PaginationProps, Table, Typography } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import qs from 'qs';

import StoresService from '../../../services/stores';
import { isTobacco } from '../../../utils/isTobacco';
import { useColumns } from './useColumns';
import styles from './styles.module.scss';

const { Text } = Typography;

type Props = {
  pageName?: 'company' | 'brand' | 'barcode';
};

export const StoreStatisticsTable = ({ pageName }: Props) => {
  const location = useLocation();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 872px)' });
  const [tableData, setTableData] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [totalInfo, setTotalInfo] = useState<any[]>();

  const { data, isFetching, error } = useQuery(['salesTable', location.search, page, pageName], ({ signal }) => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
    delete params?.page;
    const type = pageName === 'company' ? 'local_company' : pageName;
    return StoresService.getStoresStatistics(
      type,
      {
        period_after: params?.period_after,
        period_before: params?.period_before,
        hashed_store_id: params?.hashed_store_id,
        mode: params?.mode,
        limit: 10,
        page: page,
        ...params,
      },
      { signal }
    );
  });

  const onPageChange: PaginationProps['onChange'] = (page) => {
    setPage(page);
  };

  const openNotification = (msgTitle: string, msgDescription?: string) => {
    notification.info({
      message: msgTitle,
      description: msgDescription,
      placement: 'bottomLeft',
    });
  };

  useEffect(() => {
    if (data) {
      setTableData(data?.data?.results);
      setTotal(data?.data?.count);
      setTotalInfo(data?.data?.additional);
    }
    if (error instanceof AxiosError) {
      if (error?.response?.status === 400) {
        openNotification('Fill required fields');
        setTableData([]);
      } else if (error?.response?.status === 403) {
        openNotification('You do not have permission to perform this action.');
        setTableData([]);
      } else {
        openNotification('Data with your request not found');
        setTableData([]);
      }
    }

    return () => {
      setTableData([]);
    };
  }, [data, error, location.search]);

  const columnsParams = useMemo(() => ({ isTabletOrMobile, pageName }), [isTabletOrMobile, pageName]);
  const columns = useColumns(columnsParams);

  return (
    <Table
      columns={columns.filter((item: any) => !item['hidden']) || columns}
      dataSource={tableData}
      loading={isFetching}
      bordered
      size="small"
      scroll={{ x: 200 }}
      pagination={{
        pageSize: 10,
        defaultPageSize: 10,
        total: total,
        onChange: onPageChange,
        current: page,
        defaultCurrent: 1,
      }}
      summary={() => {
        return (
          <>
            <Table.Summary.Row>
              {totalInfo && (
                <>
                  <Table.Summary.Cell index={0} align="left">
                    <Text className={styles.total_bold}>Total</Text>
                  </Table.Summary.Cell>
                  {pageName === 'barcode' && <Table.Summary.Cell index={1} align="left" />}
                  <Table.Summary.Cell align="right" index={3}>
                    <span className={styles.summary_cell}>
                      <Text className={styles.total_bold}>
                        {`${totalInfo[0]?.value?.toString()?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ')}` || '--'}
                      </Text>
                    </span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right" index={4}>
                    <span className={styles.summary_cell}>
                      <Text className={styles.total_bold}>
                        {`${totalInfo[0]?.items?.toString()?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ')}` || '--'}
                      </Text>
                    </span>
                  </Table.Summary.Cell>
                  {isTobacco() || (
                    <Table.Summary.Cell align="right" index={5}>
                      <span className={styles.summary_cell}>
                        <Text className={styles.total_bold}>
                          {totalInfo[0]?.volume
                            ? `${totalInfo[0]?.volume?.toString()?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ')}`
                            : '--'}
                        </Text>
                      </span>
                    </Table.Summary.Cell>
                  )}
                </>
              )}
            </Table.Summary.Row>
          </>
        );
      }}
    />
  );
};
