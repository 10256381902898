import clsx from 'clsx';
import styles from './styles.module.scss';
import { isTobacco } from '../../../utils/isTobacco';

export const useColumns = ({ isTabletOrMobile, tableData, totalInfo, hiddenColumns }): any => {
  const defaultStyles: React.CSSProperties = {
    margin: 0,
    padding: 0,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: isTabletOrMobile ? '11px' : '14px',
  };
  return [
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      width: !isTabletOrMobile ? 115 : 90,
      fixed: !isTabletOrMobile ? 'left' : undefined,
    },
    ...(tableData[0]?.data?.map((item: any, index: number) => {
      const children = [
        {
          title: 'Value kzt',
          width: !isTabletOrMobile ? 130 : 100,
          align: 'right',
          hidden: hiddenColumns?.value?.value,
          className: index % 2 === 0 ? clsx(styles.column_gray, styles.bg_gray) : '',
          render: (value: any) => {
            return (
              <>
                <p style={defaultStyles}>
                  {`${value?.data[index]?.value}`?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ')}
                </p>
                <div style={{ ...defaultStyles, color: '#A9A9A9', fontWeight: 400 }}>
                  <p>Share: {value?.data[index]?.value_share} %</p>
                </div>
              </>
            );
          },
        },
        {
          title: 'Items',
          width: !isTabletOrMobile ? 125 : 90,
          align: 'right',
          hidden: hiddenColumns?.items?.value,
          className: index % 2 === 0 ? clsx(styles.column_gray, styles.bg_gray) : '',
          render: (value: any) => {
            return (
              <>
                <p style={{ ...defaultStyles, textAlign: 'right' }}>
                  {`${value?.data[index]?.items}`?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ')}
                </p>
                <div
                  style={{
                    ...defaultStyles,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    color: '#A9A9A9',
                    fontWeight: 400,
                  }}
                >
                  <p>Share: {value?.data[index]?.items_share}%</p>
                </div>
              </>
            );
          },
        },
        {
          title: 'Volume',
          width: !isTabletOrMobile ? 125 : 80,
          align: 'right',
          hidden: isTobacco() ? true : hiddenColumns?.volume?.value,
          className: index % 2 === 0 ? clsx(styles.column_gray, styles.bg_gray) : '',
          render: (value: any) => {
            return (
              <>
                <p style={defaultStyles}>
                  {`${value?.data[index]?.volume}`?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ')}
                </p>
                <div style={{ ...defaultStyles, color: '#A9A9A9', fontWeight: 400 }}>
                  <p>Share: {value?.data[index]?.volume_share}%</p>
                </div>
              </>
            );
          },
        },
        {
          title: 'Avg. price',
          dataIndex: 'data',
          key: item?.avg_price,
          width: !isTabletOrMobile ? 100 : 80,
          align: 'right',
          className: index % 2 === 0 ? clsx(styles.column_gray, styles.bg_gray_even) : '',
          render: (value: any) => {
            return `${
              value[index]?.avg_price === undefined
                ? '--'
                : value[index]?.avg_price?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ')
            }`;
          },
          hidden: hiddenColumns?.avg_price?.value,
        },
        {
          title: 'Numeric Distr.',
          dataIndex: 'data',
          key: item?.numeric_distribution,
          width: !isTabletOrMobile ? 100 : 80,
          align: 'right',
          className: index % 2 === 0 ? clsx(styles.column_gray, styles.bg_gray_even) : '',
          render: (value: any) => {
            return `${value[index]?.numeric_distribution === undefined ? '--' : value[index]?.numeric_distribution}`;
          },
          hidden: hiddenColumns?.numeric_distribution?.value,
        },
        {
          title: 'Stores',
          dataIndex: 'data',
          key: item?.stores,
          width: !isTabletOrMobile ? 100 : 80,
          align: 'right',
          className: index % 2 === 0 ? clsx(styles.column_gray, styles.bg_gray_even) : '',
          render: (value: any) => {
            return `${value[index]?.stores === undefined ? '--' : value[index]?.stores}`;
          },
          hidden: hiddenColumns?.stores?.value,
        },
      ];
      return {
        title: totalInfo[0]?.periods[0][item?.periods] || item?.periods,
        className: index % 2 === 0 ? clsx(styles.column_gray, styles.bg_gray_even) : '',
        children: children?.filter((column) => !column.hidden),
      };
    }) || []),
  ];
};
