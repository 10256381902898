import { useMemo, useState } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { Button, Drawer, Dropdown, Menu, Tabs } from 'antd';
import { ArrowLeftOutlined, FilterOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons';
import qs from 'qs';
import { format } from 'date-fns';

import CoExistenceService from '../../services/coExistance';
// import { downloadFile } from '../../utils/downloadFile';
import { CombinationTable } from './components/CombinationTable';
import { ProductTable } from './components/ProductTable';
import { DownloadsDrawer } from '../../components/DownloadsDrawer/DownloadsDrawer';
import { openNotification } from '../../utils/lastThreeMonths';
import styles from './styles.module.scss';

export const CoExistenceCombinationsPage = () => {
  const location = useLocation();
  const { type, number_of_products } = useParams<{ type: 'sale' | 'stock'; number_of_products: string }>();
  const history = useHistory();
  // const isTabletOrMobile = useMediaQuery({ query: '(max-width: 872px)' });
  // const [openFilter, setOpenFilter] = useState(false);
  const [currentTab, setCurrentTab] = useState<string>('combination');
  const [isOpenDownloads, setIsOpenDonwloads] = useState<boolean>(false);
  const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const defaultDate = format(new Date().setMonth(new Date().getMonth() - 1), 'yyyy-MM')
    .split('-')
    .join('');

  const tabs = useMemo(
    () => [
      {
        label: 'Combination',
        key: 'combination',
        children: (
          <CombinationTable
            type={type}
            number_of_products={number_of_products}
            isActiveTab={currentTab === 'combination'}
          />
        ),
      },
      {
        label: 'Product',
        key: 'product',
        children: (
          <ProductTable type={type} number_of_products={number_of_products} isActiveTab={currentTab === 'product'} />
        ),
      },
    ],
    [type, number_of_products, currentTab]
  );

  const onChange = (key: string) => {
    setCurrentTab(key);
  };

  // const onOpenFilter = () => {
  //   setOpenFilter(true);
  // };

  // const onFilterChange = () => {
  //   setOpenFilter(false);
  // };

  // const hide = () => {
  //   setOpenFilter(false);
  // };

  const closeDownloads = () => {
    setIsOpenDonwloads(false);
  };

  const handleDownloadFile = () => {
    setExportLoading(true);
    CoExistenceService.exportCoExistenceCombination(type, {
      months: params?.months || defaultDate,
      brand: params?.brand,
      number_of_products: number_of_products,
      ...params,
    })
      .then((res) => {
        setIsOpenDonwloads(true);
        openNotification('File added to downloads folder', 'success');
        // downloadFile(
        //   res.data,
        //   `report_${type}_combination_${params?.months || defaultDate}` || 'coexistence_combination_report'
        // );
      })
      .finally(() => setExportLoading(false));
  };

  return (
    <div className={styles.company_layout_content}>
      <div>
        <Button icon={<ArrowLeftOutlined />} onClick={() => history.goBack()}>
          Back
        </Button>
      </div>
      <div className={styles.top_bar}>
        <div className={styles.wrapper}>
          <div className={styles.title}>Co-Existance Combination</div>
          {/* {!isTabletOrMobile && (
            <>
              <div style={{ marginLeft: '15px' }}>
                <Drawer title="" placement="left" onClose={hide} open={openFilter}>
                  <CustomFilter pageName="co-existence" type="brand" onFilterChange={onFilterChange} onCancel={hide} />
                </Drawer>
                <Button
                  type="ghost"
                  className={styles.company_filter_btn}
                  onClick={onOpenFilter}
                  icon={<FilterOutlined />}
                >
                  &nbsp;&nbsp;Filter
                </Button>
              </div>
            </>
          )} */}
        </div>
        &nbsp;&nbsp;&nbsp;
        <Button type="default" className={styles.export_btn} onClick={handleDownloadFile} loading={exportLoading}>
          <VerticalAlignBottomOutlined />
          Export
        </Button>
      </div>
      {/* {isTabletOrMobile && (
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', overflowX: 'auto' }}>
          <div>
            <Drawer title="" placement="left" onClose={hide} open={openFilter}>
              <CustomFilter pageName="co-existence" type="brand" onFilterChange={onFilterChange} onCancel={hide} />
            </Drawer>
            <Button type="ghost" className={styles.company_filter_btn} onClick={onOpenFilter} icon={<FilterOutlined />}>
              &nbsp;&nbsp;Filter
            </Button>
          </div>
        </div>
      )} */}
      <Tabs defaultActiveKey={currentTab} onChange={onChange} items={tabs} />
      <Drawer title="Downloads" width="auto" placement="right" onClose={closeDownloads} open={isOpenDownloads}>
        <DownloadsDrawer open={isOpenDownloads} />
      </Drawer>
    </div>
  );
};
