import FilterService from '../services/filter';

let timeout: ReturnType<typeof setTimeout> | null;

export const fetchLocalCompany = (value: string, callback: Function, subcategory?: string) => {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }

  const fetchLocalCompanies = () => {
    FilterService.getLocalCompany({ search: value, subcategory: subcategory || undefined }).then((res) => {
      const data = res.data?.results?.map((item: any) => ({ label: item['name_val'], value: item['id'] }));
      callback(data);
    });
  };

  if (value) {
    timeout = setTimeout(fetchLocalCompanies, 500);
  }
};
