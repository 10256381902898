import { useState } from 'react';
import { Radio, RadioChangeEvent, Space } from 'antd';

export const SearchModeInfo = () => {
  const [tabValue, setTabValue] = useState<'en' | 'ru'>('ru');

  const changeTabPosition = (e: RadioChangeEvent) => {
    setTabValue(e.target.value);
  };
  return (
    <>
      <Space style={{ marginTop: 16, marginBottom: 16 }}>
        <Radio.Group value={tabValue} onChange={changeTabPosition}>
          <Radio.Button value="ru">RU</Radio.Button>
          <Radio.Button value="en">EN</Radio.Button>
        </Radio.Group>
      </Space>
      {tabValue === 'ru' ? (
        <p style={{ width: '300px', height: '70px' }}>
          Include - поиск торговых точек, где обнаружены указанные фильтры. <br /> Exclude - поиск торговых точек, где
          указанные фильтры отсутствуют.
        </p>
      ) : (
        <p style={{ width: '300px', height: '70px' }}>
          Include - show stores where selected filters are present. <br />
          Exclude - show stores where selected filters are absent.
        </p>
      )}
    </>
  );
};
