import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Button, Drawer, Dropdown, RadioChangeEvent } from 'antd';
import { FilterOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons';
import qs from 'qs';
import { useMediaQuery } from 'react-responsive';

import { CustomFilter } from '../../components/filter/CustomFilter';
// import { downloadFile } from '../../utils/downloadFile';
import ComparisonShareService from '../../services/comparisonShare';
import { ComparisonShareTable } from './components/ComparisonShareTable';
import { ToggleMenuItems } from '../../components/toggleMenuItems/ToggleMenuitems';
import { RadioGroupMenu } from '../../components/RadioGroupMenu/RadioGroupMenu';
import { isTobacco } from '../../utils/isTobacco';
import { openNotification } from '../../utils/lastThreeMonths';
import { DownloadsDrawer } from '../../components/DownloadsDrawer/DownloadsDrawer';
import styles from './styles.module.scss';

export const ComparisonSharePage = () => {
  const { type } = useParams<{ type: string; tab: string }>();
  const history = useHistory();
  const location = useLocation();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 872px)' });
  const [openFilter, setOpenFilter] = useState(false);
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  // const queryParams = new URLSearchParams(location.search);
  const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
  // const typeOfCompany = JSON.parse(localStorage.getItem('user'))?.company?.categories[0]?.category;
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState<string>('company');
  const [isOpenDownloads, setIsOpenDonwloads] = useState<boolean>(false);

  const [hiddenColumns, setHiddenColumns] = useState({
    // value_share: { title: 'Value share', value: false },
    value: { title: 'Value', value: false },
    items: { title: 'Items', value: false },
    // items_share: { title: 'Items share', value: true },
    ...(!isTobacco() && { volume: { title: 'Volume', value: true } }),
    // ...(typeOfCompany !== 'tobacco' && { volume_share: { title: 'Volume share', value: true } }),
    avg_price: { title: 'Avg. price', value: true },
    numeric_distribution: { title: 'Numeric distr.', value: true },
    stores: { title: 'Stores', value: true },
  });

  const onChangePage = ({ target: { value } }: RadioChangeEvent) => {
    setPage(value);
    history.replace({ pathname: `/comparison-share/${value}`, search: history.location.search });
  };

  useEffect(() => {
    if (location.pathname.includes('company')) {
      setPage('company');
    } else if (location.pathname.includes('brand')) {
      setPage('brand');
    } else if (location.pathname.includes('barcode')) {
      setPage('barcode');
    }
    return () => {
      setPage('company');
    };
  }, [location.pathname]);

  const handleToggle = (key) => {
    setHiddenColumns((prevColumns) => ({
      ...prevColumns,
      [key]: { ...prevColumns[key], value: !prevColumns[key].value },
    }));
  };

  const handleOpenChange = (flag: boolean) => {
    setOpen(flag);
  };

  const onOpenFilter = () => {
    setOpenFilter(true);
  };

  const onFilterChange = () => {
    setOpenFilter(false);
  };

  const hide = () => {
    setOpenFilter(false);
  };

  const closeDownloads = () => {
    setIsOpenDonwloads(false);
  };

  const handleDownloadFile = () => {
    setExportLoading(true);
    ComparisonShareService.exportByType(type === 'company' ? 'local_company' : type, {
      period_1: params?.period_1,
      period_2: params?.period_2,
      local_company: params?.local_company,
      ...params,
    })
      .then((res) => {
        setIsOpenDonwloads(true);
        openNotification('File added to downloads folder', 'success');
        // downloadFile(res.data, `comparison_${type}` || 'comparison_share_report');
      })
      .finally(() => setExportLoading(false));
  };

  return (
    <>
      <div className={styles.company_layout_content}>
        <div className={styles.top_bar}>
          <div className={styles.wrapper}>
            {!isTabletOrMobile && (
              <div className={styles.title}>
                <RadioGroupMenu onChange={onChangePage} value={page} />
              </div>
            )}
            {isTabletOrMobile && (
              <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', overflowX: 'auto' }}>
                <div>
                  <Drawer title="" placement="left" onClose={hide} open={openFilter}>
                    <CustomFilter
                      type={type}
                      onFilterChange={onFilterChange}
                      disabledMode
                      pageName="comparison"
                      onCancel={hide}
                      isBarcodeRequired={type === 'barcode'}
                      localCompanyBrandRequired={type !== 'barcode'}
                    />
                  </Drawer>
                  <Button
                    type="default"
                    className={styles.company_filter_btn}
                    onClick={onOpenFilter}
                    icon={<FilterOutlined />}
                  />
                </div>
              </div>
            )}
            {!isTabletOrMobile && (
              <>
                <div style={{ marginLeft: '15px' }}>
                  <Drawer title="" placement="left" onClose={hide} open={openFilter}>
                    <CustomFilter
                      type={type}
                      onFilterChange={onFilterChange}
                      disabledMode
                      pageName="comparison"
                      onCancel={hide}
                      isBarcodeRequired={type === 'barcode'}
                      localCompanyBrandRequired={type !== 'barcode'}
                    />
                  </Drawer>
                  <Button
                    type="default"
                    className={styles.company_filter_btn}
                    onClick={onOpenFilter}
                    icon={<FilterOutlined />}
                  >
                    &nbsp;&nbsp;Filter
                  </Button>
                </div>
              </>
            )}
          </div>
          {!isTabletOrMobile && (
            <Dropdown
              menu={ToggleMenuItems(hiddenColumns, handleToggle)}
              placement="bottom"
              onOpenChange={handleOpenChange}
              open={open}
            >
              <Button>Table settings</Button>
            </Dropdown>
          )}
          &nbsp;&nbsp;&nbsp;
          <Button type="default" className={styles.export_btn} onClick={handleDownloadFile} loading={exportLoading}>
            <VerticalAlignBottomOutlined />
            Export
          </Button>
        </div>

        <ComparisonShareTable pageName={type} hiddenColumns={hiddenColumns} />
      </div>
      <Drawer title="Downloads" width="auto" placement="right" onClose={closeDownloads} open={isOpenDownloads}>
        <DownloadsDrawer open={isOpenDownloads} />
      </Drawer>
    </>
  );
};
