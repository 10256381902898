import { Modal, Radio, RadioChangeEvent, Space } from 'antd';
import { useState } from 'react';
import info from '../../../assets/info.jpeg';

type Props = {
  open: boolean;
  close: () => void;
  isMobile?: boolean;
};

export const InformationModal = ({ open, close, isMobile }: Props) => {
  const [tabValue, setTabValue] = useState<'en' | 'ru'>('ru');

  const changeTabPosition = (e: RadioChangeEvent) => {
    setTabValue(e.target.value);
  };

  return (
    <Modal title={''} open={open} onCancel={close} width={600} style={{ top: 20 }} footer={null}>
      <Space style={{ marginTop: 16, marginBottom: 16 }}>
        <Radio.Group value={tabValue} onChange={changeTabPosition}>
          <Radio.Button value="ru">RU</Radio.Button>
          <Radio.Button value="en">EN</Radio.Button>
        </Radio.Group>
      </Space>
      {tabValue === 'ru' ? (
        <>
          <p>
            <b>Co-Existence</b> - отчет, который организует магазины на основе количества продуктов (SKU), которые они
            продали или имеют в своем инвентаре. Таблица "<b>Stock</b>" отображает данные в реальном времени, а таблица
            "<b>Sales</b>" отображает данные за выбранный период.
          </p>
          <p>
            Пример: Мы выбираем Бренд: X, размер упаковки: 1 литр, тип упаковки: PET. В результате у нас есть 3 SKU,
            соответствующие выбранным параметрам.
          </p>
          <p style={{ margin: 0, padding: 0 }}>В отчете ниже мы видим, что:</p>
          <p style={{ margin: 0, padding: 0 }}>5 магазинов продали 1 из 3 SKU.</p>
          <p style={{ margin: 0, padding: 0 }}>3 магазина продали 2 из 3 SKU.</p>
          <p style={{ margin: 0, padding: 0 }}>2 магазина продали 3 из 3 SKU.</p>
        </>
      ) : (
        <>
          <p>
            <b>Co-Existence</b> - report that organizes stores based on the quantity of products (SKU) they have sold or
            possess in their stock. Table “<b>Stock</b>” shows data in real time and table “<b>Sales</b>” shows data for
            the chosen period.
          </p>
          <p>
            Example: We choose Brand: X, size of package: 1 liter, type of package: PET. As a result, we have 3 SKUs
            corresponding to the selected parameters.
          </p>
          <p style={{ margin: 0, padding: 0 }}>In the report below we can see that:</p>
          <p style={{ margin: 0, padding: 0 }}>5 stores sold 1 of 3 SKU.</p>
          <p style={{ margin: 0, padding: 0 }}>3 stores sold 2 of 3 SKU.</p>
          <p style={{ margin: 0, padding: 0 }}>2 stores sold 3 of 3 SKU.</p>
        </>
      )}
      <div style={{ display: 'flex', overflowX: isMobile ? 'scroll' : 'auto' }}>
        <img src={info} alt="" width={560} height={386} style={{ marginLeft: '-20px', margin: 0, padding: 0 }} />
      </div>
    </Modal>
  );
};
