import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { MenuProps } from 'antd/lib/menu';

export const ToggleMenuItems = (columns: Record<string, any>, handleToggle: (key: string) => void): MenuProps => ({
  items: Object.keys(columns).map((column: string) => ({
    key: column,
    label: (
      <div
        onClick={() => handleToggle(column)}
        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
      >
        {columns[column].title} {columns[column].value ? <EyeInvisibleOutlined /> : <EyeOutlined />}
      </div>
    ),
  })),
});
