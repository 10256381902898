export const hasPermissions = (permission: string): boolean => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');

  const hasCompanyPermission = (name: string): boolean => {
    return user?.role?.company?.modules?.some((companyPermission: { name: string }) => companyPermission.name === name);
  };

  const hasUserPermission = user?.role?.permissions?.includes(permission);

  return hasUserPermission && hasCompanyPermission(permission);
};
