import { useCallback, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Button, Drawer, Table } from 'antd';
import { FilterOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons';
import qs from 'qs';
import { useMediaQuery } from 'react-responsive';

import { StoresTable } from './components/StoresTable';
// import { downloadFile } from '../../utils/downloadFile';
import StoresService from '../../services/stores';
import { openNotification } from '../../utils/lastThreeMonths';
import { DownloadsDrawer } from '../../components/DownloadsDrawer/DownloadsDrawer';
import { StoresFilter } from './components/StoresFilter';
import styles from './styles.module.scss';

export const StoresPage = () => {
  const { type } = useParams<{ type: string }>();
  const location = useLocation();
  const history = useHistory();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 872px)' });
  const [openFilter, setOpenFilter] = useState(false);
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
  const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
  const rules = JSON.parse(localStorage.getItem('user'))?.role?.regions?.join(',');
  const [isOpenDownloads, setIsOpenDonwloads] = useState<boolean>(false);
  const [option, setOption] = useState<'value' | 'item' | 'volume'>('value');

  const onOpenFilter = () => {
    setOpenFilter(true);
  };

  const onFilterChange = () => {
    setOpenFilter(false);
  };

  const hide = () => {
    setOpenFilter(false);
  };

  const onSelectChange = (newSelectedRowKeys: any[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const closeDownloads = () => {
    setIsOpenDonwloads(false);
  };

  const onSelectOption = (option: 'value' | 'item' | 'volume') => {
    setOption(option);
  };

  const rowSelection = {
    selections: [
      {
        key: 'All',
        text: 'Select all stores',
        onSelect: (changableRowKeys) => {
          setIsAllSelected(true);
          setSelectedRowKeys(changableRowKeys);
        },
      },
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
    ],
    onChange: onSelectChange,
    selectedRowKeys: selectedRowKeys,
    preserveSelectedRowKeys: true,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const handleGenerateReport = () => {
    const searchParams = new URLSearchParams(history.location.search);

    searchParams.delete('hashed_store_id');

    if (isAllSelected) {
      history.push({
        pathname: `/stores-statistics/sales/company`,
        search: searchParams.toString(),
      });
    } else {
      const str = selectedRowKeys?.join(',');
      searchParams.append('hashed_store_id', str);
      history.push({
        pathname: `/stores-statistics/sales/company`,
        search: searchParams.toString(),
      });
    }
  };

  const handleDownloadFile = () => {
    setExportLoading(true);
    StoresService.exportSalesByStores({
      period_after: params?.period_after,
      period_before: params?.period_before,
      mode: params?.mode || 'include',
      city: params?.city || rules || '24',
      limit: +params?.limit || 10,
      measure: option,
      ...params,
    })
      .then((res) => {
        setIsOpenDonwloads(true);
        openNotification('File added to downloads folder', 'success');
      })
      .finally(() => setExportLoading(false));
  };

  return (
    <>
      <div className={styles.company_layout_content}>
        <div className={styles.top_bar}>
          <div className={styles.wrapper}>
            {isTabletOrMobile && (
              <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', overflowX: 'auto' }}>
                <div>
                  <Drawer
                    title=""
                    placement="left"
                    onClose={hide}
                    open={openFilter}
                    width={isTabletOrMobile ? '320' : undefined}
                  >
                    <StoresFilter
                      pageName="stores"
                      type={'barcode'}
                      isDynamicValuesHidden
                      onFilterChange={onFilterChange}
                      onCancel={hide}
                    />
                  </Drawer>
                  <Button
                    type="default"
                    className={styles.company_filter_btn}
                    onClick={onOpenFilter}
                    icon={<FilterOutlined />}
                  />
                  &nbsp;&nbsp;&nbsp;
                  <Button
                    type="primary"
                    disabled={!hasSelected}
                    className={styles.company_filter_btn}
                    onClick={handleGenerateReport}
                  >
                    &nbsp;&nbsp;Report
                  </Button>
                </div>
              </div>
            )}
            {!isTabletOrMobile && (
              <>
                <div style={{ display: 'flex' }}>
                  <Drawer title="" placement="left" onClose={hide} open={openFilter}>
                    <StoresFilter
                      pageName="stores"
                      type={'barcode'}
                      isDynamicValuesHidden
                      onFilterChange={onFilterChange}
                      onCancel={hide}
                    />
                  </Drawer>
                  <Button
                    type="default"
                    className={styles.company_filter_btn}
                    onClick={onOpenFilter}
                    icon={<FilterOutlined />}
                  >
                    &nbsp;&nbsp;Filter
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                  <Button
                    type="primary"
                    disabled={!hasSelected}
                    className={styles.company_filter_btn}
                    onClick={handleGenerateReport}
                    // icon={<FilterOutlined />}
                  >
                    &nbsp;&nbsp;Report
                  </Button>
                </div>
              </>
            )}
          </div>
          &nbsp;&nbsp;&nbsp;
          <Button type="default" className={styles.export_btn} onClick={handleDownloadFile} loading={exportLoading}>
            <VerticalAlignBottomOutlined />
            Export
          </Button>
        </div>

        <StoresTable
          pageName={type}
          rule={rules}
          rowSelection={rowSelection}
          option={option}
          onSelectOption={onSelectOption}
        />
      </div>
      <Drawer title="Downloads" width="auto" placement="right" onClose={closeDownloads} open={isOpenDownloads}>
        <DownloadsDrawer open={isOpenDownloads} />
      </Drawer>
    </>
  );
};
