import styles from './styles.module.scss';
import topBlock from '../../../../assets/landing/topblock.png';

export const TopBlock = () => {
  return (
    <section className={styles.root}>
      <h1>
        <span>Данные</span> - это ключ к принятию обоснованных и эффективных решений!
      </h1>
      <p className={styles.info}>
        Datum.kz - это платформа BI аналитики. Мы предлагаем комплексные аналитические инструменты, данные в режиме
        реального времени и реальные данные продаж торговых точек по всему Казахстану
      </p>
      <div className={styles.picture}>
        <img src={topBlock} alt="" />
      </div>
    </section>
  );
};
