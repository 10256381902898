import styles from './styles.module.scss';
import map from '../../../../assets/landing/map.png';

export const Map = ({ mapRef }) => {
  return (
    <section className={styles.root} ref={mapRef}>
      <h2>География</h2>
      <p className={styles.info}>
        Облачный сбор данных из торговых точек подключенных к системе автоматизации. Более чем 5000 торговых точек
      </p>
      <div className={styles.picture}>
        <img src={map} alt="" />
      </div>
    </section>
  );
};
