import { Layout } from 'antd';
import { ReactElement } from 'react';
import { Profile } from '../../models';
import { NavHeader as Header } from '../header/Header';
import './protectedLayout.css';

const { Content } = Layout;

type Props = {
  children: ReactElement[] | ReactElement;
  user: Profile;
  logout: () => void;
};

export const ProtectedLayout = ({ children, user, logout }: Props) => {
  return (
    <Layout className="layout">
      <Header user={user} logout={logout} />
      <Content
        style={{
          backgroundColor: '#EEEEEE',
          height: '100vh',
        }}
      >
        {children}
      </Content>
    </Layout>
  );
};
