import { AxiosRequestConfig, AxiosResponse } from 'axios';
import fetchClient from './axiosInstance';

interface ComparisonProductQueryParams {
  period_after: string;
  period_before: string;
  barcode_1: string;
  barcode_2: string;
  local_company_1?: string;
  local_company_2?: string;
  city?: string;
  limit?: number;
  page?: number;
}
export default class ComparisonProductService {
  static async getComparisonProduct(
    params: ComparisonProductQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/product_comparison/`, { params, ...customConfig });
  }

  static async export(params: ComparisonProductQueryParams, customConfig?: AxiosRequestConfig): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/product_comparison/export/`, {
      params,
      ...customConfig,
      // responseType: 'arraybuffer',
      // headers: { 'Content-Type': 'blob' },
    });
  }
}
