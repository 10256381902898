import { useRef } from 'react';
import { PublicLayout } from '../publicLayout/PublicLayout';
import { Advantages } from './components/Advatantages/Advantages';
import { Analysis } from './components/Analysis/Analysis';
import { Categories } from './components/Categories/Categories';
import { ContactUs } from './components/ContactUs/ContactUs';
import { Footer } from './components/Footer/Footer';
import { Map } from './components/Map/Map';
import { TopBlock } from './components/TopBlock/TopBlock';
import styles from './styles.module.scss';

export const HomePage = () => {
  const topBlockRef = useRef<HTMLDivElement>(null);
  const advantagesRef = useRef(null);
  const mapRef = useRef(null);
  // const categoriesRef = useRef(null);
  const contactUsRef = useRef(null);

  const handleItemClick = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  return (
    <PublicLayout onItemClick={handleItemClick} refs={{ topBlockRef, advantagesRef, mapRef, contactUsRef }}>
      <div className={styles.layout}>
        <div ref={topBlockRef}>
          <TopBlock />
        </div>
        <Analysis />
      </div>
      <Advantages advantagesRef={advantagesRef} />
      <div className={styles.layout}>
        <Map mapRef={mapRef} />
        <Categories />
      </div>
      <ContactUs contactUsRef={contactUsRef} />
      <Footer onItemClick={handleItemClick} refs={{ topBlockRef, advantagesRef, mapRef, contactUsRef }} />
    </PublicLayout>
  );
};
