import { AxiosRequestConfig } from 'axios';
import fetchClient from './axiosInstance';
import { PageQueryParams } from '../models';

export default class DownloadsService {
  static async getDownloadsList(params: PageQueryParams, customConfig?: AxiosRequestConfig) {
    const bodyParams = {
      ...params,
      ordering: '-create_time',
    };
    return fetchClient.get(`/api/v1/export/`, { params: bodyParams, ...customConfig }).then((res) => res.data);
  }
}
