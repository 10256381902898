import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';

export const useColumns = ({ tableData, periodLabels, onOpenInfoModal }): any => {
  return [
    {
      title: 'Product name',
      dataIndex: 'name',
      key: 'name',
      width: 255,
      fixed: 'left',
    },
    {
      title: 'Period',
      dataIndex: 'periods_data',
      children: tableData[0]?.periods_data?.map((item: any, index: number) => {
        return {
          title: periodLabels[index] || item?.period,
          key: item,
          children: [
            {
              title: 'Items',
              dataIndex: `periods_data`,
              key: item?.items,
              width: 120,
              render: (value: any) => {
                return `${value[index]?.items === null ? '--' : value[index]?.items}`;
              },
            },
            {
              title: 'Avg. price',
              dataIndex: 'periods_data',
              key: item?.avg_price,
              width: 120,
              render: (value: any) => {
                return `${value[index]?.avg_price === null ? '--' : value[index]?.avg_price}`;
              },
            },
          ],
        };
      }),
    },
    {
      title: 'Change',
      key: 'calculations_data',
      children: tableData[0]?.calculations_data?.map((item: any, index: number) => {
        return {
          title: '',
          key: item,
          children: [
            {
              title: '% Change of items',
              dataIndex: 'calculations_data',
              key: item?.item_percent,
              width: 120,
              render: (value: any) => {
                return `${value[index]?.item_percent === null ? '--' : value[index]?.item_percent}`;
              },
            },
            {
              title: '% Change of price',
              dataIndex: 'calculations_data',
              key: item?.price_percent,
              width: 120,
              render: (value: any) => {
                return `${value[index]?.price_percent === null ? '--' : value[index]?.price_percent}`;
              },
            },
            {
              title: (
                <>
                  Price elasticity
                  <Button shape="circle" icon={<QuestionCircleOutlined />} type="text" onClick={onOpenInfoModal} />
                </>
              ),
              dataIndex: 'calculations_data',
              key: item?.price_elasticity,
              width: 120,
              render: (value: any) => {
                return `${value[index]?.price_elasticity === null ? '--' : value[index]?.price_elasticity}`;
              },
            },
          ],
        };
      }),
    },
  ];
};
