import styles from './styles.module.scss';
import rectangeIcon from '../../../../assets/landing/rectangeIcon.svg';

const fixtures = [
  {
    title: 'Безалкогольные напитки',
    items: ['Газированные напитки', 'Холодный чай', 'Вода', 'Энергетические напитки', 'Соки'],
    isActive: true,
  },
  {
    title: 'Табачная продукция',
    items: ['Сигареты', 'Нагреваемые табачные продукты', 'Устройства для нагревания табака', 'Электронные сигареты'],
    isActive: true,
  },
  {
    title: 'Алкогольные напитки',
    items: ['Пиво', 'Вино', 'Вода', 'Шампанское', 'Виски', 'Водка', 'Коньяк'],
    isActive: true,
  },
  {
    title: 'Молочные продукты',
    items: ['Скоро'],
    isActive: false,
  },
  {
    title: 'Кондитерcкие изделия',
    items: ['Скоро'],
    isActive: false,
  },
];

export const Categories = () => {
  return (
    <section className={styles.root}>
      <h2>Категории</h2>
      <div className={styles.cardList}>
        {fixtures.map((item, index) => (
          <div key={index} className={styles.card}>
            <p className={item.isActive ? styles.cardTitle : styles.notActive}>{item.title}</p>
            {item.isActive ? <div className={styles.line} /> : <div className={styles.notActiveline} />}
            <div className={styles.list}>
              {item.isActive ? (
                item.items.map((el, index) => (
                  <div key={index} className={styles.item}>
                    <img src={rectangeIcon} alt="" />
                    <p className={styles.activeItem}>{el}</p>
                  </div>
                ))
              ) : (
                <p className={styles.soonItem} style={{ textAlign: 'center', marginTop: '20px' }}>
                  Скоро
                </p>
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};
