import { AxiosRequestConfig, AxiosResponse } from 'axios';
import fetchClient from './axiosInstance';

interface BuyingBehaviorQueryParams {
  months: string;
  step?: string | number;
  city?: string;
  brand?: string;
  barcode?: string;
  local_company?: string;
  size_of_package?: string;
  subcategory?: string;
  type_of_package?: string;
  active_stores?: boolean;
  validated_stores?: boolean;
}

export default class BuyingBehaviorService {
  static async getPrice(params: BuyingBehaviorQueryParams, customConfig?: AxiosRequestConfig): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/buying_behavior/price/`, { params, ...customConfig });
  }

  static async getVolume(params: BuyingBehaviorQueryParams, customConfig?: AxiosRequestConfig): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/buying_behavior/volume/`, { params, ...customConfig });
  }

  static async getWeek(params: BuyingBehaviorQueryParams, customConfig?: AxiosRequestConfig): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/buying_behavior/week/`, { params, ...customConfig });
  }
}
