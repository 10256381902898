import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Card, notification, Pagination, PaginationProps } from 'antd';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import qs from 'qs';

import CoExistenceService from '../../../services/coExistance';
import { Loader } from '../../../components/loader/Loader';

type Props = {
  type: 'sale' | 'stock';
  number_of_products: string;
  isActiveTab?: boolean;
};

const gridStyle: React.CSSProperties = {
  margin: 0,
  padding: 0,
};

export const CombinationTable = ({ type, number_of_products, isActiveTab }: Props) => {
  const location = useLocation();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 872px)' });
  const [tableData, setTableData] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState(1);

  const { data, isFetching, error } = useQuery(
    ['combinationTable', type, isActiveTab, location.search, page],
    ({ signal }) => {
      const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
      delete params?.page;
      switch (type) {
        case 'sale':
          return CoExistenceService.getCoExistenceCombinationSale(
            {
              brand: params?.brand,
              number_of_products: number_of_products || params?.number_of_products,
              limit: 10,
              page: page,
              ...params,
            },
            { signal }
          );

        case 'stock':
          return CoExistenceService.getCoExistenceCombinationStock(
            {
              brand: params?.brand,
              number_of_products: number_of_products || params?.number_of_products,
              limit: 10,
              page: page,
              ...params,
            },
            { signal }
          );
        default:
          return;
      }
    }
  );

  const onPageChange: PaginationProps['onChange'] = (page) => {
    setPage(page);
  };

  const openNotification = (msgTitle: string, msgDescription?: string) => {
    notification.info({
      message: msgTitle,
      description: msgDescription,
      placement: 'bottomLeft',
    });
  };

  useEffect(() => {
    if (data) {
      setTableData(data?.data?.results);
      setTotal(data?.data?.count);
    }
    if (error instanceof AxiosError) {
      if (error?.response?.status === 400) {
        openNotification('Fill required fields');
        setTableData([]);
      } else if (error?.response?.status === 403) {
        openNotification('You do not have permission to perform this action.');
        setTableData([]);
      } else {
        openNotification('Data with your request not found');
        setTableData([]);
      }
    }

    return () => {
      setTableData([]);
    };
  }, [data, error, location.search]);

  return (
    <div style={{ width: '100%' }}>
      {isFetching ? (
        <Loader />
      ) : (
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
          {tableData?.map((item, cardIndex) => (
            <Card
              title={<>{item?.stores} Stores</>}
              key={`${item?.stores}-${cardIndex}`}
              headStyle={{ backgroundColor: '#e8e8e8' }}
              style={{ marginBottom: 20, width: isTabletOrMobile ? '100%' : '47%' }}
              bodyStyle={{ padding: 0 }}
            >
              <div
                style={{ display: 'flex', flexDirection: 'row', width: '100%', textAlign: 'center', fontWeight: 700 }}
              >
                <Card.Grid style={{ ...gridStyle, width: '50%' }}>Product</Card.Grid>
                <Card.Grid style={{ ...gridStyle, width: '50%' }}>Barcode</Card.Grid>
              </div>
              {item?.products?.map((dataItem, index) => (
                <div
                  key={`${dataItem?.barcode}-${index}`}
                  style={{ display: 'flex', flexDirection: 'row', textAlign: 'center' }}
                >
                  <Card.Grid style={{ ...gridStyle, width: '50%' }}>{dataItem?.name_val}</Card.Grid>
                  <Card.Grid style={{ ...gridStyle, width: '50%' }}>{dataItem?.barcode}</Card.Grid>
                </div>
              ))}
            </Card>
          ))}
        </div>
      )}
      <Pagination
        size="small"
        onChange={onPageChange}
        total={total}
        current={page}
        defaultPageSize={10}
        defaultCurrent={1}
      />
    </div>
  );
};
