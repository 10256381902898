import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';
import { Button, Form, Input, Select } from 'antd';

import { removeEmptyObject } from '../../utils/removeEmptyObject';
import { SelectItem } from '../form';
import { Brand } from '../../models';
import PermissionsService from '../../services/permissions';
import FilterService from '../../services/filter';
import { fetchLocalCompany } from '../../utils/fetchLocalCompany';
import './filter.css';

type Props = {
  onFilterChange?: () => void;
  pageType?: string;
};

const statuses = [
  {
    label: 'Active',
    value: true,
  },
  { label: 'Inactive', value: false },
  {
    label: 'All',
    value: '',
  },
];

export const UserFilter = ({ onFilterChange, pageType }: Props) => {
  const history = useHistory();
  const location = useLocation();
  const [filtersForm] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [roles, setRoles] = useState<Brand[]>([]);
  const valuesFromUrl = qs.parse(history.location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
  const [companies, setCompanies] = useState<any[]>([]);

  const initialValues = {
    is_active: valuesFromUrl?.is_active,
    ...(valuesFromUrl?.role && { role: valuesFromUrl?.role ? valuesFromUrl?.role.split(',').map(Number) : [] }),
  };

  const onFinish = (values: any) => {
    let normalized = {} as any;
    Object.keys(values).forEach((key) => {
      if (values[key] != null) {
        if (Array.isArray(values[key])) {
          normalized[key] = values[key].join(',');
        } else {
          normalized[key] = values[key];
        }
      }
    });
    history.replace({
      pathname: history.location.pathname,
      search: `page=1&limit=10&${qs.stringify(removeEmptyObject(normalized))}`,
    });
  };

  const handleSearchLocalCompany = (value) => {
    fetchLocalCompany(value, setCompanies);
  };

  const getLocalCompanies = useCallback(() => {
    FilterService.getLocalCompany()
      .then((res) => {
        setCompanies(res.data?.results?.map((item: any) => ({ label: item['name_val'], value: item['id'] })));
      })
      .catch((err) => setCompanies([]));
  }, []);

  const fetchDefaultFilters = useCallback(() => {
    setLoading(true);
    PermissionsService.getRoles({ limit: 1000 })
      .then((res) => {
        setRoles(res.data.results.map((item: any) => ({ label: item['name'], value: item['id'] })));
      })
      .catch((err) => setRoles([]))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    fetchDefaultFilters();
    getLocalCompanies();
    return () => {
      setRoles([]);
      setCompanies([]);
    };
  }, [fetchDefaultFilters, getLocalCompanies]);

  useEffect(() => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
    if (Object.keys(params).length === 0) {
      filtersForm.resetFields(null);
    }
  }, [location.search, filtersForm]);

  return (
    <Form form={filtersForm} layout="inline" onFinish={onFinish} initialValues={initialValues}>
      <div className="user-filter-container">
        <Form.Item name="search">
          <Input placeholder="Search" allowClear />
        </Form.Item>

        {pageType === 'users' && (
          <SelectItem
            name="role"
            mode="multiple"
            options={roles}
            placeholder="Choose a role"
            loading={loading}
            maxTagCount="responsive"
            allowClear={true}
            style={{ width: '156px', marginLeft: '16px' }}
          />
        )}
        <Form.Item name="is_active" style={{ marginLeft: '16px' }}>
          <Select options={statuses} allowClear={true} placeholder="Select status" style={{ width: '156px' }} />
        </Form.Item>
        <Button type="default" htmlType="submit">
          Apply
        </Button>
      </div>
    </Form>
  );
};
