import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { PageQueryParams } from '../models/pageQueryParams';
import fetchClient from './axiosInstance';

interface CoExistenceQueryParams extends PageQueryParams {
  months?: string;
  brand: string;
  city?: string;
  barcode?: string;
  local_company?: string;
  size_of_package?: string;
  subcategory?: string;
  type_of_package?: string;
  active_stores?: boolean;
  validated_stores?: boolean;
}

interface CoExistenceCombinationQueryParams extends CoExistenceQueryParams {
  number_of_products: string;
}

export default class CoExistenceService {
  static async getSale(params: CoExistenceQueryParams, customConfig?: AxiosRequestConfig): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/coexistence/sale/`, { params, ...customConfig });
  }

  static async getStock(params: CoExistenceQueryParams, customConfig?: AxiosRequestConfig): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/coexistence/stock/`, { params, ...customConfig });
  }

  static async exportByType(
    type: 'stock' | 'sale',
    params: CoExistenceQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/coexistence/${type}_export/`, {
      params,
      ...customConfig,
      // responseType: 'arraybuffer',
      // headers: { 'Content-Type': 'blob' },
    });
  }

  static async getCoExistanceTrends(
    params: CoExistenceQueryParams,
    customConfig: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/coexistence_trends/sale/`, { params, ...customConfig });
  }

  static async exportCoExistanceTrends(
    params: CoExistenceQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/coexistence_trends/sale_export/`, {
      params,
      ...customConfig,
      // responseType: 'arraybuffer',
      // headers: { 'Content-Type': 'blob' },
    });
  }

  static async getCoExistenceCombinationSale(
    params: CoExistenceCombinationQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/coexistence_combination/sale/`, { params, ...customConfig });
  }

  static async getCoExistenceCombinationSaleProduct(
    params: CoExistenceCombinationQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/coexistence_combination/sale_product/`, { params, ...customConfig });
  }

  static async exportCoExistenceCombination(
    type: 'sale' | 'stock',
    params: CoExistenceCombinationQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/coexistence_combination/${type}_export/`, {
      params,
      ...customConfig,
      // responseType: 'arraybuffer',
      // headers: { 'Content-Type': 'blob' },
    });
  }

  static async getCoExistenceCombinationStock(
    params: CoExistenceCombinationQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/coexistence_combination/stock/`, { params, ...customConfig });
  }

  static async getCoExistenceCombinationStockProduct(
    params: CoExistenceCombinationQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/coexistence_combination/stock_product/`, { params, ...customConfig });
  }
}
