import { InfoCircleOutlined } from '@ant-design/icons';
import DownRedIcon from '../../../assets/down-red.svg';
import UpGreenIcon from '../../../assets/up-green.svg';
import { Switch } from 'antd';

export const useColumns = ({
  pageName,
  isMobile,
  tab,
  tableData,
  setOpenModal,
  isShareActive,
  setIsShareActive,
}): any => {
  const defaultStyles: React.CSSProperties = {
    margin: 0,
    padding: 0,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontSize: isMobile ? '11px' : '14px',
  };
  return [
    {
      title: 'Product name',
      width: 296,
      key: 'barcode',
      hidden: pageName === 'company' || pageName === 'brand',
      render: (value: any) => {
        return (
          <>
            <p style={defaultStyles}>{value.product_name}</p>
            <p
              style={{
                color: '#A9A9A9',
                fontWeight: 400,
                ...defaultStyles,
              }}
            >
              {value.brand} | {value.local_company}
            </p>
          </>
        );
      },
      fixed: !isMobile ? 'left' : undefined,
    },
    {
      title: 'Barcode',
      key: 'barcode',
      width: 124,
      hidden: pageName === 'company' || pageName === 'brand',
      render: (value: any) => {
        return <p style={defaultStyles}>{value?.barcode}</p>;
      },
    },
    {
      title: 'Brand',
      width: 192,
      key: 'brand',
      hidden: pageName !== 'brand',
      render: (value: any) => {
        return (
          <>
            <p style={defaultStyles}>{value.brand}</p>
            <p
              style={{
                color: '#A9A9A9',
                fontWeight: 400,
                ...defaultStyles,
              }}
            >
              {value.local_company}
            </p>
          </>
        );
      },
      fixed: !isMobile ? 'left' : undefined,
    },
    {
      title: 'Local company',
      width: 188,
      key: 'local_company',
      hidden: pageName === 'brand' || pageName === 'barcode',
      render: (value: any) => {
        return <p style={defaultStyles}>{value.local_company}</p>;
      },
      fixed: !isMobile ? 'left' : undefined,
    },
    ...(tableData[0]?.data?.map((item: any, index: number) => {
      const children = [
        {
          title: tab,
          width: 137,
          align: 'right',
          render: (value: any) => {
            return (
              <>
                <p
                  style={{
                    textAlign: 'right',
                    ...defaultStyles,
                  }}
                >
                  {`${value?.data[index]?.linear}`?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ')}
                </p>
                <p
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    color: '#A9A9A9',
                    fontWeight: 400,
                    ...defaultStyles,
                  }}
                >
                  Share: {value?.data[index]?.share} %
                </p>
              </>
            );
          },
        },
        {
          title: (
            <span style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p style={{ cursor: 'pointer', ...defaultStyles }} onClick={() => setOpenModal(true)}>
                <InfoCircleOutlined />
              </p>
              <p style={{ padding: 0, margin: 0 }}>
                {' '}
                Change &nbsp;&nbsp;
                <Switch
                  size="small"
                  checked={isShareActive}
                  onChange={(checked) => setIsShareActive(checked)}
                  checkedChildren="%"
                  unCheckedChildren="₸"
                />{' '}
              </p>
            </span>
          ),
          width: 155,
          align: 'right',
          render: (value: any) => {
            return isShareActive ? (
              <>
                <span
                  style={{
                    display: 'flex',
                    justifyContent: value?.data[index]?.share_absolute === '--' ? 'flex-end' : 'space-between',
                  }}
                >
                  {value?.data[index]?.share_absolute === '--' ? (
                    ''
                  ) : (
                    <img src={value?.data[index]?.share_absolute > 0 ? UpGreenIcon : DownRedIcon} alt="red" />
                  )}
                  <p style={defaultStyles}>Absolute: {value?.data[index]?.share_absolute} %</p>
                </span>

                <span
                  style={{
                    display: 'flex',
                    justifyContent: value?.data[index]?.share_relative === '--' ? 'flex-end' : 'space-between',
                  }}
                >
                  {value?.data[index]?.share_relative === '--' ? (
                    ''
                  ) : (
                    <img src={value?.data[index]?.share_relative > 0 ? UpGreenIcon : DownRedIcon} alt="green" />
                  )}
                  <p style={defaultStyles}>Relative: {value?.data[index]?.share_relative} %</p>
                </span>
              </>
            ) : (
              <>
                <span
                  style={{
                    display: 'flex',
                    justifyContent: value?.data[index]?.absolute === '--' ? 'flex-end' : 'space-between',
                  }}
                >
                  {value?.data[index]?.absolute === '--' ? (
                    ''
                  ) : (
                    <img src={value?.data[index]?.absolute > 0 ? UpGreenIcon : DownRedIcon} alt="red" />
                  )}
                  <p style={defaultStyles}>Absolute: {value?.data[index]?.absolute} %</p>
                </span>

                <span
                  style={{
                    display: 'flex',
                    justifyContent: value?.data[index]?.relative === '--' ? 'flex-end' : 'space-between',
                  }}
                >
                  {value?.data[index]?.relative === '--' ? (
                    ''
                  ) : (
                    <img src={value?.data[index]?.relative > 0 ? UpGreenIcon : DownRedIcon} alt="green" />
                  )}
                  <p style={defaultStyles}>Relative: {value?.data[index]?.relative} %</p>
                </span>
              </>
            );
          },
        },
      ];
      return {
        title: item?.month_year,
        children,
        align: 'center',
      };
    }) || []),
  ];
};
