import { ComparisonTable } from './components/ComparisonTable';
import { ComparisonChart } from './components/ComparisonChart';

type Props = {
  tab: string;
  pageName: string;
  mode: string;
};

export const ComparisonCategory = ({ tab, pageName, mode = 'table' }: Props) => {
  return (
    <>
      {mode === 'table' ? (
        <ComparisonTable tab={tab} pageName={pageName} />
      ) : (
        <ComparisonChart tab={tab} pageName={pageName} />
      )}
    </>
  );
};
