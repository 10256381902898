import { Card } from 'antd';

import { isTobacco } from '../../../utils/isTobacco';
import styles from './styles.module.scss';
import clsx from 'clsx';

type Props = {
  tableData: any[];
  isTabletOrMobile?: boolean;
  totalData?: any[];
};

export const TrendsShareCard = ({ tableData, isTabletOrMobile, totalData }: Props) => {
  const defaultStyles: React.CSSProperties = {
    margin: 0,
    padding: 0,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  };

  return (
    <>
      {tableData?.map((item) => (
        <Card
          key={item?.city}
          title={item?.city}
          style={{ marginBottom: 20, display: 'flex', flexDirection: 'column' }}
          bodyStyle={{ padding: 0 }}
        >
          <div className={styles?.card_body}>
            <div className={styles.market_card_title}>
              <Card.Grid
                className={styles.share_cell_item}
                style={{ width: '100%', padding: '4px 0 0 10px', textAlign: 'start' }}
              >
                Period
              </Card.Grid>
              <Card.Grid
                className={styles.share_cell_item}
                style={{ textAlign: 'start', width: '100%', padding: '0 0 0 10px' }}
              >
                Value
              </Card.Grid>

              <Card.Grid
                className={styles.share_cell_item}
                style={{ textAlign: 'start', width: '100%', padding: '0 0 0 10px' }}
              >
                Items
              </Card.Grid>
              {!isTobacco() && (
                <Card.Grid
                  className={styles.share_cell_item}
                  style={{ textAlign: 'start', width: '100%', padding: '0 0 0 10px' }}
                >
                  Volume
                </Card.Grid>
              )}

              <Card.Grid
                className={styles.share_cell_item}
                style={{ textAlign: 'start', width: '100%', padding: '0 0 0 10px' }}
              >
                Numeric distr.
              </Card.Grid>
              <Card.Grid
                className={styles.share_cell_item}
                style={{ textAlign: 'start', width: '100%', padding: '0 10px 0 10px' }}
              >
                Weighted distr.
              </Card.Grid>
            </div>

            {item?.data?.map((column, index) => (
              <div
                key={column?.month_year}
                className={index % 2 === 0 ? clsx(styles.column_gray, styles.bg_gray) : ''}
                style={{ display: 'flex', flexDirection: 'column', flex: 1 }}
              >
                <Card.Grid
                  className={styles.share_cell_item}
                  style={{ width: '100%', padding: '4px 0 0 10px', textAlign: 'center' }}
                >
                  <b>{column?.month_year}</b>
                </Card.Grid>
                <Card.Grid className={styles.share_cell_item} style={{ width: '100%', padding: '0 10px 0 10px' }}>
                  <p style={{ ...defaultStyles, display: 'flex', justifyContent: 'flex-end' }}>
                    {`${column?.value}`?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ')}
                  </p>
                  <p className={styles.text_gray}>{column?.value_share} %</p>
                </Card.Grid>

                <Card.Grid className={styles.share_cell_item} style={{ width: '100%', padding: '0 10px 0 0' }}>
                  <p style={{ ...defaultStyles, display: 'flex', justifyContent: 'flex-end' }}>
                    {`${column?.items}`?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ')}
                  </p>
                  <p className={styles.text_gray}>{column?.items_share} %</p>
                </Card.Grid>

                {!isTobacco() && (
                  <Card.Grid
                    className={styles.share_cell_item}
                    style={{ textAlign: 'start', width: '100%', padding: '0 10px 0 0' }}
                  >
                    <p style={{ ...defaultStyles, display: 'flex', justifyContent: 'flex-end' }}>
                      {`${column?.volume}`?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ')}
                    </p>
                    <p className={styles.text_gray}>{column?.volume_share} %</p>
                  </Card.Grid>
                )}

                <Card.Grid className={styles.share_cell_item} style={{ width: '100%', padding: '0 10px 0 0' }}>
                  {column?.numeric_distribution}
                </Card.Grid>
                <Card.Grid className={styles.share_cell_item} style={{ width: '100%', padding: '0 10px 0 0' }}>
                  {column?.weighted_distribution}
                </Card.Grid>
              </div>
            ))}
          </div>
        </Card>
      ))}

      <Card
        key={totalData[0]?.city}
        title={'Total'}
        style={{ marginBottom: 20, display: 'flex', flexDirection: 'column' }}
        bodyStyle={{ padding: 0 }}
      >
        <div className={styles?.card_body}>
          <div className={styles.market_card_title}>
            <Card.Grid
              className={styles.share_cell_item}
              style={{ width: '100%', padding: '4px 0 0 10px', textAlign: 'start' }}
            >
              <b>Period</b>
            </Card.Grid>
            <Card.Grid
              className={styles.share_cell_item}
              style={{ textAlign: 'start', width: '100%', padding: '0 0 0 10px' }}
            >
              Value
            </Card.Grid>
            <Card.Grid
              className={styles.share_cell_item}
              style={{ textAlign: 'start', width: '100%', padding: '0 0 0 10px' }}
            >
              Items
            </Card.Grid>
            {!isTobacco() && (
              <Card.Grid
                className={styles.share_cell_item}
                style={{ textAlign: 'start', width: '100%', padding: '0 0 0 10px' }}
              >
                Volume
              </Card.Grid>
            )}

            <Card.Grid
              className={styles.share_cell_item}
              style={{ textAlign: 'start', width: '100%', padding: '0 0 0 10px' }}
            >
              Numeric distr.
            </Card.Grid>
            <Card.Grid
              className={styles.share_cell_item}
              style={{ textAlign: 'start', width: '100%', padding: '0 10px 0 10px' }}
            >
              Weighted distr.
            </Card.Grid>
          </div>

          {totalData[0]?.data?.map((column, index) => (
            <div
              key={column?.month_year}
              className={index % 2 === 0 ? clsx(styles.column_gray, styles.bg_gray) : ''}
              style={{ display: 'flex', flexDirection: 'column', flex: 1 }}
            >
              <Card.Grid
                className={styles.share_cell_item}
                style={{ width: '100%', padding: '4px 0 0 10px', textAlign: 'center' }}
              >
                <b>{column?.month_year}</b>
              </Card.Grid>
              <Card.Grid className={styles.share_cell_item} style={{ width: '100%', padding: '0 10px 0 10px' }}>
                <p style={{ ...defaultStyles, display: 'flex', justifyContent: 'flex-end' }}>
                  {`${column?.value}`?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ')}
                </p>
                <p className={styles.text_gray}>{column?.value_share} %</p>
              </Card.Grid>

              <Card.Grid className={styles.share_cell_item} style={{ width: '100%', padding: '0 10px 0 0' }}>
                <p style={{ ...defaultStyles, display: 'flex', justifyContent: 'flex-end' }}>
                  {`${column?.items}`?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ')}
                </p>
                <p className={styles.text_gray}>{column?.items_share} %</p>
              </Card.Grid>

              {!isTobacco() && (
                <Card.Grid
                  className={styles.share_cell_item}
                  style={{ textAlign: 'start', width: '100%', padding: '0 10px 0 0' }}
                >
                  <p style={{ ...defaultStyles, display: 'flex', justifyContent: 'flex-end' }}>
                    {`${column?.volume}`?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ')}
                  </p>
                  <p className={styles.text_gray}>{column?.volume_share} %</p>
                </Card.Grid>
              )}

              <Card.Grid className={styles.share_cell_item} style={{ width: '100%', padding: '0 10px 0 0' }}>
                {column?.numeric_distribution}
              </Card.Grid>
              <Card.Grid className={styles.share_cell_item} style={{ width: '100%', padding: '0 10px 0 0' }}>
                {column?.weighted_distribution}
              </Card.Grid>
            </div>
          ))}
        </div>
      </Card>
    </>
  );
};
