import { Tooltip } from 'antd';
import { capitalize } from '../../../utils/lastThreeMonths';

export const useColumns = ({ tab, isTabletOrMobile }): any => {
  const defaultStyles: React.CSSProperties = {
    margin: 0,
    padding: 0,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontSize: '11px',
  };
  return !isTabletOrMobile
    ? [
        {
          title: 'Product name',
          width: 225,
          key: 'barcode',
          render: (value: any) => {
            return value ? `${value.product_name} (${value.barcode})` : '-';
          },
          fixed: 'left',
        },
        {
          title: 'Brand',
          dataIndex: 'brand',
          key: 'brand',
          width: 155,
          fixed: 'left',
        },
        {
          title: capitalize(tab),
          dataIndex: 'linear',
          key: 'linear',
          width: 90,
          fixed: 'left',
        },
        {
          title: 'Share',
          dataIndex: 'share',
          key: 'share',
          width: 90,
          fixed: 'left',
        },
        {
          title: 'Cummulative Share',
          dataIndex: 'accumulative_share',
          key: 'accumulative_share',
          width: 90,
          fixed: 'left',
        },
        {
          title: 'Group',
          dataIndex: 'group',
          key: 'group',
          width: 90,
          fixed: 'left',
        },
      ]
    : [
        {
          title: 'Product name',
          width: 150,
          key: 'barcode',
          render: (value: any) => {
            return (
              <>
                <Tooltip
                  placement="topLeft"
                  title={`${value.product_name} ${value.barcode}`}
                  overlayInnerStyle={{ fontSize: 11 }}
                >
                  <p style={defaultStyles}>
                    {value.product_name} {value.barcode}
                  </p>
                </Tooltip>
                <p style={{ color: '#A9A9A9', ...defaultStyles }}>{value.brand}</p>
              </>
            );
          },
        },
        {
          title: capitalize(tab),
          width: 80,
          align: 'center',
          render: (value: any) => (
            <p style={defaultStyles}>{`${value?.linear}`?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ')}</p>
          ),
        },
        {
          title: 'Cummulative Share',
          width: 80,
          align: 'center',
          render: (value: any) => (
            <>
              <p style={defaultStyles}>{value.accumulative_share ? `${value.accumulative_share} %` : '--'}</p>
              <p style={{ color: '#A9A9A9', ...defaultStyles }}>{value.share ? `${value.share} %` : '--'}</p>
            </>
          ),
        },
        {
          title: 'Group',
          width: 60,
          align: 'center',
          render: (value: any) => <p style={defaultStyles}>{value?.group}</p>,
        },
      ];
};
