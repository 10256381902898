import { AxiosRequestConfig, AxiosResponse } from 'axios';
import fetchClient from './axiosInstance';

interface OutOfStockQueryParams {
  months: string;
  local_company: string;
  city?: string;
  brand?: string;
  barcode?: string;
  size_of_package?: string;
  subcategory?: string;
  type_of_package?: string;
  active_stores?: boolean;
  validated_stores?: boolean;
}
export default class OutOfStockService {
  static async getOutOfStockByBarcode(
    params: OutOfStockQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/out_of_stock/barcode/`, { params, ...customConfig });
  }

  static async exportByType(params: OutOfStockQueryParams, customConfig?: AxiosRequestConfig): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/out_of_stock/barcode/export/`, {
      params,
      ...customConfig,
      // responseType: 'arraybuffer',
      // headers: { 'Content-Type': 'blob' },
    });
  }
}
