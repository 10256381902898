import clsx from 'clsx';
import styles from './styles.module.scss';
import { isTobacco } from '../../../utils/isTobacco';

export const useColumns = ({ isTabletOrMobile, tableData, hiddenColumns }): any => {
  const defaultStyles: React.CSSProperties = {
    margin: 0,
    padding: 0,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: isTabletOrMobile ? '11px' : '14px',
  };
  return [
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      width: 115,
      fixed: !isTabletOrMobile ? 'left' : undefined,
    },
    ...(tableData[0]?.data?.map((item: any, index: number) => {
      const children = [
        {
          title: 'Value kzt',
          width: isTabletOrMobile ? 110 : 125,
          align: 'right',
          hidden: hiddenColumns?.value?.value,
          className: index % 2 === 0 ? clsx(styles.column_gray, styles.bg_gray) : '',
          render: (value: any) => {
            return (
              <>
                <p style={{ textAlign: 'right', ...defaultStyles }}>
                  {`${value?.data[index]?.value}`?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ')}
                </p>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    color: '#A9A9A9',
                    fontWeight: 400,
                    ...defaultStyles,
                  }}
                >
                  <p>Share: {value?.data[index]?.value_share} %</p>
                </div>
              </>
            );
          },
        },
        {
          title: 'Items',
          width: isTabletOrMobile ? 100 : 125,
          align: 'right',
          hidden: hiddenColumns?.items?.value,
          className: index % 2 === 0 ? clsx(styles.column_gray, styles.bg_gray) : '',
          render: (value: any) => {
            return (
              <>
                <p style={{ textAlign: 'right', ...defaultStyles }}>
                  {`${value?.data[index]?.items}`?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ')}
                </p>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    color: '#A9A9A9',
                    fontWeight: 400,
                    ...defaultStyles,
                  }}
                >
                  <p>Share: {value?.data[index]?.items_share}%</p>
                </div>
              </>
            );
          },
        },
        {
          title: 'Volume',
          width: isTabletOrMobile ? 100 : 125,
          align: 'right',
          hidden: isTobacco() ? true : hiddenColumns?.volume?.value,
          className: index % 2 === 0 ? clsx(styles.column_gray, styles.bg_gray) : '',
          render: (value: any) => {
            return (
              <>
                <p style={defaultStyles}>
                  {`${value?.data[index]?.volume}`?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ')}
                </p>
                <div style={{ color: '#A9A9A9', fontWeight: 400, ...defaultStyles }}>
                  <p>Share: {value?.data[index]?.volume_share}%</p>
                </div>
              </>
            );
          },
        },
        {
          title: 'Avg. price',
          dataIndex: 'data',
          key: item?.avg_price,
          width: isTabletOrMobile ? 80 : 100,
          align: 'right',
          className: index % 2 === 0 ? clsx(styles.column_gray, styles.bg_gray_even) : '',
          render: (value: any) => {
            return (
              <p style={defaultStyles}>
                {value[index]?.avg_price === undefined
                  ? '--'
                  : `${value[index]?.avg_price}`?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ')}
              </p>
            );
          },
          hidden: hiddenColumns?.avg_price?.value,
        },
        {
          title: 'Numeric Distr.',
          dataIndex: 'data',
          key: item?.numeric_distribution,
          align: 'right',
          width: isTabletOrMobile ? 80 : 100,
          className: index % 2 === 0 ? clsx(styles.column_gray, styles.bg_gray_even) : '',
          render: (value: any) => {
            return (
              <p style={defaultStyles}>
                {value[index]?.numeric_distribution === undefined ? '--' : value[index]?.numeric_distribution}
              </p>
            );
          },
          hidden: hiddenColumns?.numeric_distribution?.value,
        },
        {
          title: 'Weighted Distr.',
          dataIndex: 'data',
          key: item?.weighted_distribution,
          width: isTabletOrMobile ? 80 : 100,
          align: 'right',
          className: index % 2 === 0 ? clsx(styles.column_gray, styles.bg_gray) : '',
          render: (value: any) => {
            return (
              <p style={defaultStyles}>
                {value[index]?.weighted_distribution === undefined ? '--' : value[index]?.weighted_distribution}{' '}
              </p>
            );
          },
          hidden: hiddenColumns?.weighted_distribution?.value,
        },
      ];
      return {
        title: item?.month_year || '',
        className: index % 2 === 0 ? clsx(styles.column_gray, styles.bg_gray) : '',
        children: children.filter((column) => !column.hidden),
        align: 'center',
      };
    }) || []),
  ];
};
