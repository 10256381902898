import FilterService from '../services/filter';

let timeout: ReturnType<typeof setTimeout> | null;

export const fetchBrand = (value: string, callback: Function, subcategory?: string, local_company?: string) => {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }

  const fetchBrands = () => {
    FilterService.getBrand({
      search: value,
      subcategory: subcategory || undefined,
      local_company: local_company || undefined,
    }).then((res) => {
      const data = res.data?.results?.map((item: any) => ({ label: item['name_val'], value: item['id'] }));
      callback(data);
    });
  };

  if (value) {
    timeout = setTimeout(fetchBrands, 500);
  }
};
