import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AxiosError } from 'axios';
import { notification, PaginationProps, Table } from 'antd';
import { useQuery } from 'react-query';
import qs from 'qs';

import AbcAnalysisService from '../../../services/abcAnalysis';
import styles from './styles.module.scss';
import { useColumns } from './useColumns';
import { useMediaQuery } from 'react-responsive';

type Props = {
  tab: string;
  rule?: any;
};

export const AbcAnalysisTable = ({ tab, rule }: Props) => {
  const location = useLocation();
  const history = useHistory();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 872px)' });
  const [tableData, setTableData] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const page = +queryParams!.get('page') || 1;
  const limit = +queryParams!.get('limit') || 10;

  const onPageChange: PaginationProps['onChange'] = (page, pageSize) => {
    queryParams.set('page', `${page}`);
    queryParams.set('limit', `${pageSize}`);
    history.replace({ pathname: history.location.pathname, search: queryParams.toString() });
  };

  const { data, isFetching, error } = useQuery(['abcTable', tab, location.search], ({ signal }) => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
    return AbcAnalysisService.getAbcAnalysisByBarcode(
      { measure: tab, local_company: params.local_company, months: params.motnhs, ...params },
      { signal }
    );
  });

  // const getData = useCallback(() => {
  //   queryClient.refetchQueries('trendsTable', { active: true });
  // }, [queryClient]);

  const openNotification = (msgTitle: string, msgDescription?: string) => {
    notification.info({
      message: msgTitle,
      description: msgDescription,
      placement: 'bottomLeft',
    });
  };

  useEffect(() => {
    if (data) {
      setTableData(data?.data?.results);
      setTotal(data?.data?.count);
    }
    if (error instanceof AxiosError) {
      if (error?.response?.status === 400) {
        openNotification('Fill required fields');
        setTableData([]);
        setTotal(0);
      } else if (error?.response?.status === 403) {
        openNotification('You do not have permission to perform this action.');
        setTableData([]);
        setTotal(0);
      } else {
        openNotification('Data with your request not found');
        setTableData([]);
        setTotal(0);
      }
    }

    return () => {
      setTableData([]);
      setTotal(0);
    };
  }, [data, error]);

  const columnsParams = useMemo(() => ({ tab, isTabletOrMobile }), [tab, isTabletOrMobile]);
  const columns = useColumns(columnsParams);

  return (
    <>
      <Table
        columns={columns}
        dataSource={tableData}
        loading={isFetching}
        pagination={{
          pageSize: limit,
          defaultPageSize: 10,
          total: total,
          onChange: onPageChange,
          current: page,
          defaultCurrent: 1,
        }}
        className={styles.ant_table}
        sticky
        scroll={{ x: !isTabletOrMobile ? 1300 : 300 }}
        bordered
        size="middle"
      />
    </>
  );
};
