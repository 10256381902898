import { Button, MenuProps, Popover } from 'antd';
import { Link } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import Logout from '../../assets/logout.svg';

const SalesMappedTitle = {
  '/sales': 'Sales statistics',
  '/trends': 'Trends',
  '/comparison': 'Comparison',
};

const ShareMappedTitle = {
  '/market-share': 'Market share',
  '/trends-share': 'Trends share',
  '/comparison-share': 'Comparison share',
};

const AnalyticsMappedTitle = {
  '/abc-analysis': 'ABC analysis',
  '/co-existence': 'Co-Existance',
  '/out-of-stock': 'Out of Stock',
  '/buying-behavior': 'Buying behavior',
  '/price-elasticity': 'Price elasticity',
  '/comparison-product': 'Sell in / Sell out Prices',
  '/co-existence-trends': 'CoExistence trends',
  '/vpo-segmentation': 'VPO - Segmentation',
  '/vpo-trends': 'VPO - Segmentation Trends',
};

const ManageMappedTitle = {
  '/permissions/users': 'Users',
  '/permissions/roles': 'Roles',
  '/users-logs': 'User logs',
};

export const Items = (permissions: any, companyPermissions: any[], title?: string): MenuProps['items'] => {
  const hasPermission = (name: string) =>
    companyPermissions?.some((companyPermission) => companyPermission.name === name);

  return [
    {
      label: SalesMappedTitle[title] || 'Sales Report',
      key: 'sales',
      children: [
        permissions?.includes('sales_statistics') &&
          hasPermission('sales_statistics') && {
            label: <Link to="/sales/company">Sales statistics</Link>,
            key: '/sales',
          },
        permissions?.includes('trends') &&
          hasPermission('trends') && {
            label: <Link to="/trends/company/value">Trends</Link>,
            key: '/trends',
          },
        permissions?.includes('comparison') &&
          hasPermission('comparison') && {
            label: <Link to="/comparison/company/value">Comparison</Link>,
            key: '/comparison',
          },
      ],
    },
    {
      label: ShareMappedTitle[title] || 'Share by cities',
      key: 'trends',
      children: [
        permissions?.includes('market_share') &&
          hasPermission('market_share') && {
            label: <Link to="/market-share/company">Market</Link>,
            key: '/market-share',
          },
        permissions?.includes('share_trends') &&
          hasPermission('share_trends') && {
            label: <Link to="/trends-share/company">Trends</Link>,
            key: '/trends-share',
          },
        permissions?.includes('share_comparison') &&
          hasPermission('share_comparison') && {
            label: <Link to="/comparison-share/company">Comparison</Link>,
            key: '/comparison-share',
          },
      ],
    },
    permissions?.includes('sales_by_stores') &&
      hasPermission('sales_by_stores') && {
        label: <Link to="/stores">Stores</Link>,
        key: '/stores',
      },
    {
      label: AnalyticsMappedTitle[title] || 'Analytics',
      key: 'analytics',
      children: [
        permissions?.includes('abc_analysis') &&
          hasPermission('abc_analysis') && {
            label: <Link to={`/abc-analysis/value`}>ABC analysis</Link>,
            key: `/abc-analysis`,
          },
        permissions?.includes('out_of_stock') &&
          hasPermission('out_of_stock') && {
            label: <Link to="/out-of-stock">Out of stock</Link>,
            key: '/out-of-stock',
          },
        permissions?.includes('buying_behavior') &&
          hasPermission('buying_behavior') && {
            label: <Link to="/buying-behavior">Buying behavior</Link>,
            key: '/buying-behavior',
          },
        permissions?.includes('price_elasticity') &&
          hasPermission('price_elasticity') && {
            label: <Link to="/price-elasticity">Price elasticity</Link>,
            key: '/price-elasticity',
          },
        permissions?.includes('product_comparison') &&
          hasPermission('product_comparison') && {
            label: <Link to="/comparison-product">Sell in / Sell out Prices</Link>,
            key: '/comparison-product',
          },
        permissions?.includes('coexistence') &&
          hasPermission('coexistence') && {
            label: <Link to="/co-existence">Co-Existence</Link>,
            key: '/co-existence',
          },
        permissions?.includes('coexistence_trends') &&
          hasPermission('coexistence_trends') && {
            label: <Link to="/co-existence-trends">Co-Existence Trends</Link>,
            key: '/co-existence-trends',
          },
        permissions?.includes('vpo_segmentation') &&
          hasPermission('vpo_segmentation') && {
            label: <Link to="/vpo-segmentation">VPO Segmentation</Link>,
            key: '/vpo-segmentation',
          },
        permissions?.includes('vpo_trend') &&
          hasPermission('vpo_trend') && {
            label: <Link to="/vpo-trends">VPO Segmentation Trends</Link>,
            key: '/vpo-trends',
          },
        // (permissions?.basket_analysis?.includes('can_read') || companyId === 601) && {
        //   label: <Link to="/basket-analysis">Basket Analysis</Link>,
        //   key: '/basket-analysis',
        // },
      ],
    },
    {
      label: ManageMappedTitle[title] || 'Manage',
      key: 'permissions',
      children: [
        permissions?.includes('users') &&
          hasPermission('users') && {
            label: <Link to="/permissions/users">Users</Link>,
            key: '/permissions/users',
          },
        permissions?.includes('roles') &&
          hasPermission('roles') && {
            label: <Link to="/permissions/roles">Roles</Link>,
            key: '/permissions/roles',
          },
        permissions?.includes('journal') &&
          hasPermission('journal') && {
            label: <Link to="/users-logs">Users logs</Link>,
            key: '/users-logs',
          },
      ],
    },
  ];
};

export const UserMenuItems = (
  logout?: () => void,
  openFilter?: () => void,
  categories?: any[],
  defaultCategory?: string | number,
  setDefaultCategory?: (val: string) => void,
  openDownloads?: () => void,
  user?: any
): MenuProps => ({
  items: [
    {
      label: (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'default' }}>
            <UserOutlined />
            <p style={{ margin: 0, padding: 0, marginLeft: 4 }}>{user?.username}</p>
          </div>
          <Button onClick={logout} icon={<img src={Logout} alt="logout" />} />
        </div>
      ),
      key: 'user',
    },
    {
      label: <div onClick={openFilter}>Change password</div>,
      key: 'password',
    },
    {
      label: (
        <Popover
          placement="leftTop"
          content={categories?.map((item) => {
            return (
              <Button
                key={item?.id}
                type={item?.id === defaultCategory ? 'primary' : 'text'}
                onClick={(e) => {
                  setDefaultCategory(item?.id);
                  localStorage.setItem('category', item.id);
                  window.location.reload();
                }}
              >
                {item?.name_val}
              </Button>
            );
          })}
          title="Categories"
        >
          <div>Change category</div>
        </Popover>
      ),
      key: 'category',
    },
    {
      label: <div onClick={openDownloads}>Downloads</div>,
      key: 'downloads',
    },
  ],
});
