import { AxiosResponse } from 'axios';
import fetchClient from './axiosInstance';
export default class FilterService {
  static async getAvailableFilters(subcategory?: string) {
    const params = { subcategory };
    return fetchClient.get(`/api/v1/category_characteristics/?limit=1000`, { params });
  }
  static async getOptions(type: string): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/filters/${type}/`);
  }

  static getNewOptionsDev(type: string, ids?: string): Promise<AxiosResponse> {
    const params = {
      ids,
    };
    return fetchClient.get(`/api/v1/${type}/?all=true`, { params });
  }

  static getNewOptions(type: string, ids?: string, ordering?: string): Promise<AxiosResponse> {
    const params = {
      ids,
      ordering,
    };
    return fetchClient.get(`/api/v1/${type}/?all=true`, { params });
  }

  static async getCities(all?: boolean): Promise<AxiosResponse> {
    const params = {
      all,
    };
    return fetchClient.get(`/api/v1/geo/regions/filter/`, { params });
  }

  static async getExtrapotableCities(): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/geo/regions/extrapolatable/`);
  }

  static async getBrand(params: {
    local_company?: string;
    subcategory?: string;
    search?: string;
  }): Promise<AxiosResponse> {
    const body = {
      ...params,
    };
    return fetchClient.get(`/api/v1/brand/?limit=20`, { params: body });
  }

  static async getBarcode(params: {
    local_company?: string;
    subcategory?: string;
    brand?: string;
    search?: string;
  }): Promise<AxiosResponse> {
    const body = {
      ...params,
      ordering: '-importance',
    };
    return fetchClient.get(`/api/v1/products/filter/?limit=20`, { params: body });
  }

  static async getLocalCompany(params?: {
    subcategory?: string;
    search?: string;
    all?: boolean;
  }): Promise<AxiosResponse> {
    const body = {
      ...params,
    };
    return fetchClient.get(`/api/v1/companies/local/filter/?limit=20`, { params: body });
  }

  static async getLocalCompanyRelational(params: { subcategory: string; brand: string }): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/filters/local_company/relational/?all=true`, { params });
  }

  static async getLastTransaction(report_name?: string): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/last_transaction/?module_name=${report_name}`);
  }

  static async getModules(): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/permissions/modules/?all=true`).then((res) => res.data);
  }
}
