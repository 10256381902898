import { useQuery } from 'react-query';
import FilterService from '../../../../services/filter';

export const useCities = () => {
  return useQuery('cities', async () => {
    const service = FilterService.getCities;
    const { data } = await service();
    return data?.results;
  });
};

export const useLocalCompanies = () => {
  return useQuery(['localCompanies_1'], async () => {
    const { data } = await FilterService.getLocalCompany({ all: true });
    return data?.map((item) => ({ label: item.name_val, value: item.id })) || [];
  });
};

export const useBrands = (companyString, brandsString, valuesFromUrl) => {
  return useQuery(['brands', companyString, brandsString, valuesFromUrl], async () => {
    let service = FilterService.getBrand;
    if (service) {
      const { data } = await service({
        ...(companyString && { local_company: companyString || valuesFromUrl?.local_company }),
        ...(brandsString && { search: brandsString || valuesFromUrl?.brand_1 || valuesFromUrl?.brand_2 }),
      });
      return data?.results?.map((item) => ({ label: item.name_val, value: item.id })) || [];
    }

    return [];
  });
};

export const useBarcodes = (companyString, brandsString, barcodeString, valuesFromUrl) => {
  return useQuery(['barcodes', companyString, brandsString, barcodeString, valuesFromUrl], async () => {
    const { data } = await FilterService.getBarcode({
      ...(companyString && { local_company: companyString || valuesFromUrl?.local_company }),
      ...(brandsString && { brand: brandsString || valuesFromUrl?.brand }),
      ...(barcodeString && { search: barcodeString || valuesFromUrl?.barcode }),
    });
    return data?.results?.map((item) => ({ label: item.product, value: item.barcode })) || [];
  });
};
