import { Card } from 'antd';

type Props = {
  tableData: any[];
  periodLabels?: string[];
};

const gridStyle: React.CSSProperties = {
  margin: 0,
  padding: 0,
};

export const PriceElasticityCard = ({ tableData, periodLabels }: Props) => {
  const defaultStyles: React.CSSProperties = {
    ...gridStyle,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontSize: 11,
  };

  return (
    <>
      {tableData?.map((item, index) => (
        <Card
          title={<p style={defaultStyles}>{item.name}</p>}
          key={item?.name + index}
          headStyle={{ backgroundColor: '#e8e8e8', fontWeight: 700 }}
          style={{ marginBottom: 20 }}
          bodyStyle={{ padding: 0 }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              textAlign: 'center',
              fontWeight: 500,
              ...gridStyle,
            }}
          >
            <Card.Grid style={gridStyle}>Period</Card.Grid>
            <Card.Grid style={gridStyle}>Items</Card.Grid>
            <Card.Grid style={gridStyle}>Avg. price</Card.Grid>
          </div>
          {item?.periods_data?.map((periodsData, periodIndex) => (
            <div key={periodIndex} style={{ display: 'flex', flexDirection: 'row', textAlign: 'center' }}>
              <Card.Grid style={gridStyle}>
                <p style={defaultStyles}>{periodLabels[periodIndex] || periodsData?.period}</p>
              </Card.Grid>
              <Card.Grid style={gridStyle}>
                <p style={defaultStyles}>{`${periodsData?.items}`?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ')}</p>
              </Card.Grid>
              <Card.Grid style={gridStyle}>
                <p style={defaultStyles}>{periodsData?.avg_price}</p>
              </Card.Grid>
            </div>
          ))}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              textAlign: 'center',
              fontWeight: 500,
              ...gridStyle,
            }}
          >
            <Card.Grid style={gridStyle}>% Change of items</Card.Grid>
            <Card.Grid style={gridStyle}>% Change of price</Card.Grid>
            <Card.Grid style={gridStyle}> Price elasticity</Card.Grid>
          </div>
          {item?.calculations_data?.map((calculationsData, calcIndex) => (
            <div key={calcIndex} style={{ display: 'flex', flexDirection: 'row', textAlign: 'center' }}>
              <Card.Grid style={gridStyle}>
                <p style={defaultStyles}>{calculationsData.item_percent}</p>
              </Card.Grid>
              <Card.Grid style={gridStyle}>
                <p style={defaultStyles}>{calculationsData?.price_percent}</p>
              </Card.Grid>
              <Card.Grid style={gridStyle}>
                <p style={defaultStyles}>{calculationsData?.price_elasticity}</p>
              </Card.Grid>
            </div>
          ))}
        </Card>
      ))}
    </>
  );
};
